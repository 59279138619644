<template>
  <div v-if="!headless">
    <div class="flex items-center">
        <Icon :name="icon" class="w-10 h-10 mr-3" :class="classes[status]" solid/>
        <div :class="dark ? 'text-white' : 'text-gray-700'">
          <p class="text-base leading-4">Remaining Time</p>
          <p class="text-xl font-bold">{{ status === 'expired' ? 'Expired' : formattedRemaining }}</p>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import Icon from 'vue-heroicon-next'

momentDurationFormatSetup(moment)

export default {
  name: 'SessionTimer',

  components: { Icon },

  props: {

    dark: {
      type: Boolean,
      default: false
    },

    warn: {
      type: Number,
      default: 0
    },

    crit: {
      type: Number,
      default: 0
    },

    headless: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      now: moment(),
      classes: {
        warn: 'text-yellow-600',
        crit: 'text-red-600',
        expired: 'text-red-600',
        ok: this.dark ? 'text-white' : 'text-gray-700'
      },
      notificationsSent: {
        warn: false,
        crit: false
      }
    }
  },

  computed: {
    remaining() {
      const inMinutes = moment(this.expires).diff(this.now, 'minutes')
      return moment.duration(inMinutes, 'minutes')
    },

    formattedRemaining() {
      return this.remaining.format({
        template: 'h[h] m[min]',
        minValue: 1
      })
    },

    // When does the session expire?
    expires() {
      return moment(this.$store.state.user.timer, 'x').format()
    },

    status() {
      if (this.remaining.asMinutes() <= this.warn && this.remaining.asMinutes() > this.crit) {
        return 'warn'
      } else if (this.remaining.asMinutes() <= this.crit && this.remaining.asMinutes() > 0) {
        return 'crit'
      } else if (this.remaining.asMinutes() <= 0) {
        return 'expired'
      } else {
        return 'ok'
      }
    },

    icon() {
      if (this.status == 'warn') {
        return 'exclamation'
      } else if (['crit', 'expired'].includes(status)) {
        return 'exclamation-circle'
      } else {
        return 'clock'
      }
    }
  },

  methods: {
    tick() {
      this.now = moment()

      if (this.status === 'warn' && !this.notificationsSent.warn) {
        this.$emit('warn')
        this.notificationsSent.warn = true
      }

      if (this.status === 'crit' && !this.notificationsSent.crit) {
        this.$emit('crit')
        this.notificationsSent.crit = true
      }

      if (this.status === 'expired') {
        this.$emit('expire')
        clearInterval(this.ticker)
      }
    }
  },

  mounted() {
    this.ticker = setInterval(this.tick, 60 * 1000)
  },

  destroyed() {
    clearInterval(this.ticker)
  }
}
</script>
