import EventService from '@/services/EventService.js'
import auth from '@/services/auth'
import snackbar from '@/behaviors/snackbar'
import settings from '@/services/settings'

export default {
    async EventServicePartnerLogin(_, data) {
        try {
            await EventService.partnerLogin(data)
            return true
        } catch (err) {
          let newErr
          if (typeof(err.response.data) == 'string') {
            newErr = new Error(err.response.data)
          } else {
            newErr = new Error(err.response.data.error.message)
            newErr.status = err.response.status
          }
          throw newErr
        }
    },

    async EventServiceUserLogout(_, data) {
        let autologout = 0
        if (data && data.autologout) {
            autologout = data.autologout
        }
        await EventService.userLogout({ autologout })
    },

    async fetchCurrentUser(context) {
        try {
            const user = await auth.currentUser()

            context.commit('MUTATESTATE', {
                key: 'user',
                value: user
            })
        } catch (error) {
            console.error(error)

            snackbar.show({
                tone: 'critical',
                timeout: 3000,
                message: 'There was an error attempting to fetch authenticated user.'
            })
        }
    },

    async sendUserSettingsUpdate(context) {
        const currentSettings = context.state.user.settings
        try {
            await settings.updateUserSettings(currentSettings)
            snackbar.show({
                tone: 'positive',
                timeout: 3000,
                message: 'Your settings have been saved.'
            })
        }
        catch (err) {
            console.error(err)
            snackbar.show({
                tone: 'critical',
                timeout: 3000,
                message: 'There was an error updating your user settings.'
            })
        }
    },

    async requestCluster(context) {
        try {
            // Send request to Rx for cluster/existing res details
            await auth.requestCluster()
            // Update current user data in state and session
            const user = await auth.currentUser()

            context.commit('MUTATESTATE', {
                key: 'user',
                value: user
            })

            snackbar.show({
                tone: 'positive',
                timeout: 3000,
                message: 'Cluster request successful!'
            })
            return true
        }
        catch (err) {
            console.error(err)
            snackbar.show({
                tone: 'critical',
                timeout: 5000,
                message: 'There was an error requesting a cluster for your session.'
            })
            return false
        }
    }
}
