
<template>
  <div class="relative">
    <button @click="toggle" type="button" class="block focus:outline-none" @focus="buttonHasFocus = true" @blur="buttonHasFocus = false">
      <slot name="trigger" :hasFocus="buttonHasFocus" :isOpen="isOpen"></slot>
    </button>
    <div :class="[isOpen ? 'block' : 'hidden']">
      <button @click="isOpen = false" type="button" class="z-30 block fixed inset-0 w-full h-full cursor-default"></button>
      <div :class="[dropdownClasses.all, dropdownClasses[direction]]">
        <slot name="dropdown" :toggle="toggle"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',

  props: {
    direction: {
      type: String,
      validator: (val) => ['top', 'bottom'].includes(val),
      default: 'bottom'
    }
  },

  data() {
    return {
      buttonHasFocus: false,
      isOpen: false,
      dropdownClasses: {
        all: 'absolute z-40',
        top: 'left-0 bottom-full mb-2',
        bottom: 'left-0 top-full mt-2'
      }
    }
  },

  mounted() {
    document.addEventListener('keydown', this.onEscape)
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.onEscape)
  },

  methods: {
    onEscape(e) {
      if (!this.isOpen || e.key !== 'Escape') {
        return
      }
      this.isOpen = false
    },

    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>
