<template>
  <div id="app" class="min-h-screen antialiased flex flex-col h-screen">
    <BuildInformation />
    <router-view :key="$route.fullPath" />

    <!--
      Headless SessionTimer tracks dynamic behaviour. All other SessionTimer
      instances should shy away from global snackbars and all that. Let this one
      handle it. If you need to trigger local behaviour, feel free.
    -->
    <SessionTimer headless :warn="30" :crit="10" @crit="showSessionEndingSoon" @expire="logout" />

    <!--
      This component is responsible for monitoring user activity and if the user
      becomes idle for some maximum threshold, we show a modal and if they take
      action, the timer resets. If after 30 seconds, they do nothing, response to
      @inactive by logging them out
    -->
    <ActivityMonitor :enabled="monitorActivity" maxIdle="15m" @inactive="handleInactiveUser" />

    <!-- Global snackbar used by @/behaviors/snackbar -->
    <Snackbar v-model="snack" :position="snackbar.position" :timeout="snackbar.timeout" :tone="snackbar.tone"><span  v-html="snackbar.message"/></Snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";

import { ActivityMonitor, Snackbar, SessionTimer, BuildInformation } from '@/components'
import snackbar from '@/behaviors/snackbar'
import { launch } from '@/behaviors/launcher'

export default {
  name: 'App',

  components: { ActivityMonitor, Snackbar, SessionTimer, BuildInformation },

  data() {
    return {}
  },

  computed: {
    ...mapState('ui', ['snackbar']),

    snack: {
      set(visible) {
        this.showSnackbar({
          ...this.snackbar,
          show: false,
        })
      },

      get() {
        return this.snackbar.show
      }
    },

    monitorActivity() {
      return this.$route.name !== 'login'
    }
  },

  mounted() {
    /**
     * In DemoFrame, we register some click handlers on events that
     * use postMessage to communicate back _here_. This is where we handle
     * "ngdNavigationEvent" to trigger launching of products in Demo.
     *
     * Code search terms: `ngd-navigation-event`, `ngdNavigationEvent`
     */
    window.addEventListener('message', e => {
      if (e.data.location == 'prismElement') {
        launch({
          protocol: 'https',
          serviceName: 'prismElement',
          subService: 'vip',
          target: e.data.target,
          id: 0
        })
      }
      if (e.data.event === 'ngdNavigationEvent') {
        if (e.data.location == 'prismCentral') {
          launch({
            protocol: 'https',
            serviceName: 'prismCentral',
            subService: 'vip',
            id: 1
          })
        }
        if (e.data.location == 'fileAnalytics') {
          launch({
            protocol: 'https',
            serviceName: 'files',
            subService: 'analytics',
            id: 6
          })
        }
        if (e.data.location == 'fileServer') {
          launch({
            protocol: 'https',
            serviceName: 'files',
            subService: 'server',
            id: 5
          })
        }
        if (e.data.location == 'filesConsole') {
          launch({
            protocol: 'https',
            serviceName: 'files',
            subService: 'console',
            target: e.data.target,
            id: 5
          })
        }
      }
    })
  },

  methods: {
    ...mapMutations('ui', ['showSnackbar']),
    ...mapActions(['EventServiceUserLogout']),

    showSessionEndingSoon() {
      snackbar.show({
        tone: 'critical',
        message: 'There are less than 10 minutes remaining in this session.',
        timeout: 10000
      })
    },

    handleInactiveUser() {
      this.logout(1)
    },

    async logout(autologout = 0) {
      this.EventServiceUserLogout({ autologout })
      snackbar.dismiss()
      this.$router.push({ name: 'login' })
      this.$gtag.event('logout')
    }
  }
}
</script>

<style src="./assets/css/tailwind.css"/>
