<template>
  <Dropdown class="hidden sm:block">
    <template #trigger>
      <Icon name="menu" outline class="w-6 h-6 mr-6 text-white" />
    </template>

    <template #dropdown="{ toggle }">
      <div class="mt-3 bg-white xl:border rounded-md overflow-hidden w-auto whitespace-no-wrap shadow-lg">
        <div class="bg-white py-1">
          <div class="p-2 py-1  text-sm">
            <router-link class="group flex items-center rounded hover:text-white text-gray-800 w-64 px-3 py-2 hover:bg-blue-500" :to="{ name: 'landing' }" @click.native="() => { toggle() }">
              <Icon name="home" outline class="mr-3 w-5 h-5 text-gray-500 group-hover:text-white" />
              Home
            </router-link>

            <router-link class="group flex items-center rounded hover:text-white text-gray-800 px-3 py-2 hover:bg-blue-500" :to="{ name: 'settings' }" @click.native="() => { toggle() }">
              <Icon name="cog" outline class="mr-3 w-5 h-5 text-gray-500 group-hover:text-white" />
              Account Settings
            </router-link>

            <router-link class="group flex items-center rounded hover:text-white text-gray-800 w-64 px-3 py-2 hover:bg-blue-500" :to="{ name: 'landing' }" @click.native="(e) => { toggle(); $emit('toggleConfigMode', e) }">
              <Icon name="eye" outline class="mr-3 w-5 h-5 text-gray-500 group-hover:text-white" />
              Configure View
            </router-link>
          </div>

          <div class="px-2 py-1  text-sm">
            <div class="px-3 my-2">
              <h3 class="uppercase text-sm text-gray-500 font-bold tracking-wide">Need Help?</h3>
            </div>

            <router-link class="group flex items-center rounded hover:text-white text-gray-800 px-3 py-2 hover:bg-blue-500" :to="{ name: 'faq' }" @click.native="() => { toggle() }">
              <Icon name="question-mark-circle" outline class="mr-3 w-5 h-5 text-gray-500 group-hover:text-white" />
              FAQ
            </router-link>

            <!-- <router-link class="group flex items-center rounded hover:text-white text-gray-800 px-3 py-2 hover:bg-blue-500" :to="{ name: 'issues' }" @click.native="() => { toggle() }">
              <Icon name="annotation" outline class="mr-3 w-5 h-5 text-gray-500 group-hover:text-white" />
              Issue Tracker
            </router-link> -->

            <a :href="slackChannel" target="_blank" class="group flex items-center rounded hover:text-white text-gray-800 px-3 py-2 hover:bg-blue-500" @click="() => { toggle() }">
              <svg class="w-5 h-5 mr-3 text-gray-500 group-hover:text-white"  viewBox="0 0 17 17" fill="currentColor">
                <path d="M3.44 10.3467C3.44 11.2933 2.66667 12.0667 1.72 12.0667C0.773334 12.0667 0 11.2933 0 10.3467C0 9.39998 0.773334 8.62665 1.72 8.62665H3.44V10.3467Z"/>
                <path d="M4.30664 10.3466C4.30664 9.39998 5.07997 8.62665 6.02664 8.62665C6.9733 8.62665 7.74664 9.39998 7.74664 10.3466V14.6533C7.74664 15.6 6.9733 16.3733 6.02664 16.3733C5.07997 16.3733 4.30664 15.6 4.30664 14.6533V10.3466Z"/>
                <path d="M6.02664 3.44C5.07997 3.44 4.30664 2.66667 4.30664 1.72C4.30664 0.773333 5.07997 0 6.02664 0C6.9733 0 7.74664 0.773333 7.74664 1.72V3.44H6.02664Z"/>
                <path d="M6.02667 4.30664C6.97333 4.30664 7.74666 5.07998 7.74666 6.02664C7.74666 6.97331 6.97333 7.74665 6.02667 7.74665H1.72C0.773333 7.74665 0 6.97331 0 6.02664C0 5.07998 0.773333 4.30664 1.72 4.30664H6.02667Z"/>
                <path d="M12.9333 6.02664C12.9333 5.07998 13.7066 4.30664 14.6533 4.30664C15.6 4.30664 16.3733 5.07998 16.3733 6.02664C16.3733 6.97331 15.6 7.74665 14.6533 7.74665H12.9333V6.02664Z"/>
                <path d="M12.0666 6.02667C12.0666 6.97334 11.2933 7.74667 10.3466 7.74667C9.39998 7.74667 8.62665 6.97334 8.62665 6.02667V1.72C8.62665 0.773334 9.39998 0 10.3466 0C11.2933 0 12.0666 0.773334 12.0666 1.72V6.02667Z"/>
                <path d="M10.3466 12.9333C11.2933 12.9333 12.0666 13.7067 12.0666 14.6533C12.0666 15.6 11.2933 16.3733 10.3466 16.3733C9.39998 16.3733 8.62665 15.6 8.62665 14.6533V12.9333H10.3466Z"/>
                <path d="M10.3466 12.0667C9.39998 12.0667 8.62665 11.2933 8.62665 10.3467C8.62665 9.39998 9.39998 8.62665 10.3466 8.62665H14.6533C15.6 8.62665 16.3733 9.39998 16.3733 10.3467C16.3733 11.2933 15.6 12.0667 14.6533 12.0667H10.3466Z"/>
              </svg>
              Slack
            </a>

            <a href="mailto:oe-support@nutanix.com" class="group flex items-center rounded hover:text-white text-gray-800 px-3 py-2 hover:bg-blue-500" @click="() => { toggle() }">
              <Icon name="mail" outline class="mr-3 w-5 h-5 text-gray-500 group-hover:text-white" />
              Email
            </a>
          </div>
          <div class="border-t border-gray-200 my-1"></div>
          <div class="px-2 py-1 text-sm">
            <a href="#" class="group flex items-center rounded hover:text-white text-gray-800 px-3 py-2 hover:bg-blue-500" @click.prevent="() => { toggle(); $emit('logout') }">
              <Icon name="logout" outline class="mr-3 w-5 h-5 text-gray-500 group-hover:text-white" />
              Sign Out
            </a>
          </div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import { Dropdown } from '@/components'
import Icon from 'vue-heroicon-next'

export default {
  name: 'HamburgerMenu',

  props: ['slackChannel'],

  components: { Dropdown, Icon }
}
</script>
