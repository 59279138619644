<template>
  <div>
    <DemoFrame v-if="!hasGuac" style="z-index:1"></DemoFrame>
    <DemoGuac v-else style="z-index:1"></DemoGuac>
    <FooterMenu style="z-index:2"></FooterMenu>
  </div>
</template>

<script>
import { DemoGuac, DemoFrame } from '@/components'
import FooterMenu from '@/components/FooterMenu'
import { mapState } from 'vuex'

export default {
  name: 'Live',

  components: { DemoFrame, DemoGuac, FooterMenu },

  computed: {
    ...mapState(['hasGuac'])
  }
}
</script>
