<template>
  <div>
    <div>
      <h3 class="text-lg leading-6 font-medium text-gray-900">{{ title }}</h3>
      <p class="mt-1 text-sm leading-5 text-gray-500">{{ description }}</p>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'FieldGroup',

  props: {
    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    }
  }
}
</script>
