// import store from '../js/store.js'
import axios from 'axios'

const apiClient = axios.create({
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})
const axeos = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    partnerLogin(data) {
        const creds = {
            username: data.username,
            password: data.password
        }
        return axeos.post('/auth/partner', creds)
    },
    userLogout(data) {
        return (apiClient.delete('/api/v1/userLogout', {
            data: {
                autologout: data.autologout
            }
        }))
    }
}