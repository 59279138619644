import api from './api'

export async function currentUser() {
  const response = await api.get(`/api/v1/me`)
  return {
    username: response.data.username,
    partner: response.data.partner,
    settings: response.data.settings,
    hasCluster: response.data.hasCluster,
    timer: response.data.timer
  }
}

export async function requestCluster() {
  const response = await api.get(`/api/v1/requestCluster`)

  return response.data
}

export default {
  currentUser, requestCluster
}
