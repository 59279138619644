export default {
    SHOWACCOUNTSETTINGS: (state) => {
        state.accountPageVisible = !state.accountPageVisible
    },
    TOGGLECONFIGMODE: (state) => {
        state.configMode = !state.configMode
    },
    DISABLECONFIGMODE: (state) => {
      state.configMode = false
    },
    SETIFRAMESOURCE: (state, source) => {
        state.iframeSource = source
    },
    MUTATESTATE: (state, data) => {
        state[data.key] = data.value
    },
    TOGGLEHIDDENTILE: (state, id) => {
        // Remove tile if it's already hidden
        if (state.user.settings.hiddenTiles.indexOf(id) > -1) {
            state.user.settings.hiddenTiles = state.user.settings.hiddenTiles.filter(ht => ht !== id)
        }
        // Hide tile if not hidden
        else {
            state.user.settings.hiddenTiles.push(id)
        }
    },
    RESETHIDDENTILES: (state) => {
        state.user.settings.hiddenTiles = []
    },
    TOGGLEHEADERTIMER: (state, val) => {
        state.user.settings.timerInHeader = val
    },
    TOGGLEFOOTERTIMER: (state, val) => {
        state.user.settings.timerInFooter = val
    },
    CHANGEPRISMLANGUAGE: (state, val) => {
        state.user.settings.chosenPrismLanguage = val
    },
    CHANGEPRISMREGION: (state, val) => {
        state.user.settings.chosenPrismRegion = val
    }
}
