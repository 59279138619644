<template>
  <div>
    <Header />
    <div class="bg-white py-10">
      <div class="max-w-5xl mx-auto px-8">
        <h1 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Welcome to Next-Gen Demo!</h1>
        <p class="mt-8 text-l leading-7 text-gray-600">Over the years we’ve received a lot
          of feedback from the SE community on their pain points with demo.nutanix.com. We’ve re-architected
          the platform to solve many of these issues and provide new ways to demo nearly the entire Nutanix catalog of products on demand.
          The <strong>Next-Gen Demo environment</strong>, or <strong>NGD</strong> for short, offers a dedicated environment for each and every demo user.
          We hope to work with the product teams to integrate supplemental resources to help you win deals.</p>
      </div>
      <div class="mt-12">
        <div class="sticky top-0 mx-auto px-8 bg-white z-10">
          <div>
            <div class="border-b border-gray-200">
              <nav class="-mb-px flex">
                <a href="#new-features" class="w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-600 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300">
                  New Features
                </a>
                <a href="#getting-help" class="w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-600 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300">
                  Getting Help
                </a>
                <a href="#environment-overview" class="w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-600 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300">
                  Environment Overview
                </a>
                <a href="#known-issues" class="w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-600 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300">
                  Known Issues/Limitations
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div id="new-features" class="max-w-5xl mx-auto px-8 mb-16 mt-16">
          <div class="mt-16">
            <h2 class="mt-2 text-3xl tracking-tight leading-7 text-gray-800 sm:text-3xl sm:leading-7 text-center">New
              Features</h2>
          </div>
          <div class="mt-16">
            <ul class="md:grid md:grid-cols-3 md:col-gap-8 md:row-gap-10">
              <li>
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-10 w-10 rounded-md bg-blue-700 text-white">
                      <Icon name="user" outline class="h-6 w-6" />
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-base leading-6 font-medium text-gray-900">Dedicated Nested Cluster</h5>
                    <p class="mt-2 text-sm leading-6 text-gray-600">Each user has their own dedicated environment that
                      is delivered in a fresh state.</p>
                  </div>
                </div>
              </li>
              <li class="mt-10 md:mt-0">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-10 w-10 rounded-md bg-blue-700 text-white">
                      <Icon name="desktop-computer" outline class="h-6 w-6" />
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-base leading-6 font-medium text-gray-900">Desktop Environment</h5>
                    <p class="mt-2 text-sm leading-6 text-gray-600">Access resources in the Nested Cluster via Windows
                      Desktop or SSH through a CentOS VM.</p>
                  </div>
                </div>
              </li>
              <li class="mt-10 md:mt-0">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-10 w-10 rounded-md bg-blue-700 text-white">
                      <Icon name="terminal" outline class="h-6 w-6" />
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-base leading-6 font-medium text-gray-900 flex">SSH to CVM + Hypervisor</h5>
                    <p class="mt-2 text-sm leading-6 text-gray-600">You're free to access CVMs and Hypervisors via SSH.
                      Dig into more advanced features available only to the command-line.</p>
                  </div>
                </div>
              </li>
              <li class="mt-10 md:mt-0">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-10 w-10 rounded-md bg-blue-700 text-white">
                      <Icon name="cursor-click" outline class="h-6 w-6" />
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-base leading-6 font-medium text-gray-900">Improved Navigation</h5>
                    <p class="mt-2 text-sm leading-6 text-gray-600">Navigate from service-to-service using our service
                      selector tool. No more having to jump to the landing page to get where you're going.</p>
                  </div>
                </div>
              </li>
              <li class="mt-10 md:mt-0">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-10 w-10 rounded-md bg-blue-700 text-white">
                      <Icon name="download" outline class="h-6 w-6" />
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-base leading-6 font-medium text-gray-900">Additional Resources</h5>
                    <p class="mt-2 text-sm leading-6 text-gray-600">Enrich your demos with resources from the product
                      teams that show our customers why they should buy our products.</p>
                  </div>
                </div>
              </li>
              <li class="mt-10 md:mt-0">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-10 w-10 rounded-md bg-blue-700 text-white">
                      <Icon name="cog" outline class="h-6 w-6" />
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-base leading-6 font-medium text-gray-900">Customizable</h5>
                    <p class="mt-2 text-sm leading-6 text-gray-600">Tweak the Demo interface to suit your needs.
                      Customize product tiles that appear, Prism language and regional settings, and more.</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div id="getting-help" class="bg-semi-gray text-gray-900">
          <div class="max-w-5xl mx-auto px-8 pt-16 pb-16">
            <h2 class="text-3xl tracking-tight leading-7 text-gray-900 sm:text-3xl sm:leading-7 text-center">Getting Help</h2>
            <div class="rounded-md bg-blue-100 p-4 mt-8">
              <div class="flex">
                <div class="flex-shrink-0">
                  <Icon name="information-circle" outline class="h-6 w-6 text-blue-600" />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm leading-5 font-bold text-blue-800">
                    We Value Your Feedback!
                  </h3>
                  <div class="mt-2 text-sm leading-5 text-blue-700">
                    <p>
                      NGD is a new product that is still undergoing iteration. While we've done extensive testing thanks to our team of SEs,
                      we know there are edge cases we probably have not yet uncovered. As we’re continuing to refine the product,
                      we appreciate and need your feedback.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="mt-8 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div>
                <ul class="mt-10">
                  <li>
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                          <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                          </svg> </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium">Check Reported Issues</h5>
                        <p class="mt-2 text-base leading-6 text-gray-600">
                          Wondering if we've seen a particular issue you've ran into? Check to see what other users have reported or issues the dev team have already identified.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                          <Icon name="clipboard-list" class="h-6 w-6 bg-blue-700" />
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">Report An Issue</h5>
                        <p class="mt-2 text-base leading-6 text-gray-600">
                          Ran into something new that no one else has reported? Have an idea that would help you demo Nutanix? We want to know!
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                          <Icon name="lightning-bolt" class="h-6 w-6 bg-blue-700" />
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">Future Development</h5>
                        <p class="mt-2 text-base leading-6 text-gray-600">
                          We'll post updates on issues so you know which ones we're working on and what recent issues or features we've rolled out.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="mt-8 text-center">
                  <router-link :to="{}">
                    <Button color="blue" disabled v-tippy content="This feature is temporarily disabled while we migrate to a new issue tracking service.">
                      Launch Issue Tracker
                      <Icon name="arrow-right" outline class="ml-2 h-4 w-4" />
                    </Button>
                  </router-link>
                </div>

              </div>

              <div class="hidden md:block h-full px-4">
                <div class="h-full flex items-center">
                  <img :src="require('@/assets/misc/issue-tracker.png')" alt="" />
                </div>
              </div>
            </div> -->
            <div class="flex space-x-4 items-center justify-center mt-16 mx-auto text-center">
              <img class="relative h-6 w-6 z-0" :src="require('../assets/logos/Slack_Mark.svg')" />
              <h3 class="text-xl leading-8 font-medium tracking-tight sm:text-xl sm:leading-9 text-gray-600">Employees can reach out to us via Slack at <a class="text-blue-700 hover:underline hover:text-blue-900 font-mono text-base ml-1" href="https://nutanix.slack.com/app_redirect?channel=next-gen-demo">#next-gen-demo</a></h3>
            </div>
          </div>
        </div>
        <div id="environment-overview">
          <div class="max-w-5xl mx-auto px-8 pt-16 pb-16">
            <h2 class="text-3xl tracking-tight leading-7 text-gray-900 sm:text-3xl sm:leading-7 text-center">Environment Overview</h2>
            <p class="mt-4 text-l leading-7 text-gray-600 text-center">
              NGD is a reverse-proxy that allows Nutanix employees as well as partners to access specific Nutanix assets from outside of our corporate network.
              Each user is provided with a nested, single-node cluster environment that contains nearly the entire Nutanix product catalog already deployed and ready to use.
              This reduces friction in the sales process by allowing SEs and partners to deliver on-demand demonstrations of Nutanix products to customers.
            </p>
            <div class="mt-12">
              <h3 class="text-2xl leading-8 font-extrabold tracking-tight sm:text-2xl sm:leading-9">Why Nested Clusters?</h3>
              <p class="mt-3 text-lg leading-7 text-gray-600">We chose nested clusters because they offered several advantages when combined with some elbow grease.</p>
              <div class="mt-4 pt-8 bg-white">
                <div class="mx-auto">
                  <div class="lg:grid lg:grid-cols-3 lg:gap-8">
                    <div>
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                        <Icon name="view-grid-add" class="h-6 w-6" />
                      </div>
                      <div class="mt-5">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">Repeatable</h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          By leveraging snapshots and Protection Domains, we can easily replicate the same base image to numerous parent clusters without having to foundation and re-deploy each product.
                        </p>
                      </div>
                    </div>
                    <div class="mt-10 lg:mt-0">
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                        <Icon name="cash" class="h-6 w-6" />
                      </div>
                      <div class="mt-5">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">Cost Effective</h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          With memory dense NX hardware, we're able to handle 20 concurrent nested clusters per parent 4-node physical cluster.
                        </p>
                      </div>
                    </div>
                    <div class="mt-10 lg:mt-0">
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                        <Icon name="refresh" class="h-6 w-6" />
                      </div>
                      <div class="mt-5">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">Fast Recycle</h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          Protection domains allow us to quickly destroy, redeploy and power on the cluster VMs allowing us to recycle a cluster in minutes instead of hours.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 class="text-2xl leading-8 font-extrabold tracking-tight sm:text-2xl sm:leading-9 mt-12">Cluster Architecture</h3>
            <p class="mt-4 text-l leading-7 text-gray-600">A nested cluster consists of the following core pieces:
              <ul class="grid grid-flow-col grid-cols-2 grid-rows-4 mt-4 mb-4 list-disc pl-8">
                <li class="mb-2">VyOS Virtual Router (v-router)</li>
                <li class="mb-2">Virtual CVM</li>
                <li class="mb-2">Virtual Hypervisor</li>
                <li class="mb-2">AutoAD VM</li>
                <li class="mb-2">Guacamole server VM</li>
                <li class="mb-2">CentOS desktop VM</li>
                <li class="mb-2">Windows desktop VM</li>
              </ul>
            </p>
            <p class="mt-4 text-l leading-7 text-gray-600">
              Each of the pieces is designed to operate within a private 192.168.1.0/24 network. The v-router is the only piece that gets an external address from the IPAM network of the parent physical cluster. This means the v-router is the only method of access exposed to NGD to reach inside of the private network of each nested cluster.
            </p>
            <p class="mt-4 text-l leading-7 text-gray-600">
              Additional service VMs are deployed on the nested cluster for other Nutanix services such as Objects, Calm, Files, etc.
            </p>
            <h3 class="text-2xl leading-8 font-extrabold tracking-tight sm:text-2xl sm:leading-9 mt-8">Static Resources</h3>
            <p class="mt-4 text-l leading-7 text-gray-600">All NGD users have access to a static Prism Central instance managing both an AHV and an ESXi cluster. This PC instance can be accessed without reserving a nested cluster and is not recycled between user sessions as it is shared across all users of NGD. Having learned some hard lessons in the past about static environments that are shared between many users, we decided a read-only user was the best compromise to keep that environment healthy and ready for use.</p>
            <p class="mt-4 text-l leading-7 text-gray-600">Users can also access a read-only instance of vCenter to help when demonstrating Nutanix running on ESXi. This instance is likewise shared across all users of NGD. Simply click the drop-down arrow on the "PC On-Demand" tile in order to access vCenter.</p>
            <p class="mt-4 text-l leading-7 text-gray-600">The NDB product team provides an interactive demonstration version of the NDB interface that can be accessed by launching the "NDB On-Demand" service from the home screen or by selecting "NDB On-Demand" from the service selector menu. To find out more about this instance of NDB, check out the <router-link :to="`/products/era-on-demand`" class="underline hover:text-blue-800">NDB On-Demand product page</router-link>.</p>

          </div>
        </div>
        <div id="known-issues">
          <div class="bg-semi-gray">
            <div class="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
              <div class="max-w-3xl mx-auto">
                <h2 class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                  Known Issues / Limitations
                </h2>
                <div class="mt-6 divide-y divide-gray-200">
                  <div class="py-6">
                    <h3 class="font-medium text-gray-900 text-lg mb-3">Hardware is NX Only</h3>
                    <p class="text-base leading-6 text-gray-600">As a software company, Nutanix fully supports our customers in choosing which hardware platform best meets their needs. However, the purpose of NGD is to enable our salespeople and partners to showcase the Nutanix software portfolio. As such, we've standardized our nested AHV offerings on NX hardware. We're still committed to providing access to OEM hardware through our Hosted POC lab. Partners can work with a Nutanix SE to reserve time in that lab if needed.</p>
                  </div>
                  <div class="py-6">
                    <h3 class="font-medium text-gray-900 text-lg mb-3">Recycle Is Not Perfect</h3>
                    <p class="text-base leading-6 text-gray-600">We continue to improve our recycle health checks with each release of the application, but there is still a long way to go. In the meantime you may receive a cluster where not all services have successfully started. If you find yourself in this scenario, the quickest way to resolve your issue is to log out and then log back in to NGD. This will start the recycle of your previous cluster and request a new cluster for you. We appreciate your patience as we make improvements to this process.</p>
                  </div>
                  <div class="py-6">
                    <h3 class="font-medium text-gray-900 text-lg mb-3">Physical Parent Cluster Density Is A Moving Target</h3>
                    <p class="text-base leading-6 text-gray-600">We've tried to decide on a density of nested clusters to physical parent clusters that will meet the needs of most of the users of the demo environment. However, there may be some side effects of high density that would be hard to realize without active load against the clusters. If you experience unusual performance from your cluster, please report your issues in the slack channel so that we can better tune the environment to fit emergent usage patterns.</p>
                  </div>
                  <div class="py-6">
                    <h3 class="font-medium text-gray-900 text-lg mb-3">LCM Upgrades In Prism Element Are Not Supported</h3>
                    <p class="text-base leading-6 text-gray-600">Due to limitations of nested technology combined with limitations of LCM as a service, we currently do not support LCM actions from within Prism Element. Trying to initiate an LCM inventory will fail in pre-checks. Supporting LCM in PE inside of a nested cluster will require help from engineering in order to change. The good news is that LCM operations from within Prism Central are supported. You can still show a customer how easy it is to perform an inventory and schedule an update from within the LCM interface in PC without issue.</p>
                  </div>
                  <div class="py-6">
                    <h3 class="font-medium text-gray-900 text-lg mb-3">Node Add Is Not Available</h3>
                    <p class="text-base leading-6 text-gray-600">Capacity is currently a limiting factor for this service. Because of this, we aren't able to provide additional nodes to show off the node add feature in Prism. It's possible that this feature is available in future versions of the application.</p>
                  </div>
                  <div class="py-6">
                    <h3 class="font-medium text-gray-900 text-lg mb-3">File Analytics Is A Simulation</h3>
                    <p class="text-base leading-6 text-gray-600">We use a containerized simulation of File Analytics in order to deliver an interface that displays pre-populated data. This makes it easy to show off the features of File Analytics without worrying about generating load against the Files cluster each time we recycle one of our nested clusters. The launch button on the landing page, the File Analytics option in the footer menu, and the File Analytics link in Prism Element all point to this simulated interface by default. We still provide a real File Analytics server that is accessible through the Windows desktop if needed.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header"
import Icon from "vue-heroicon-next"

export default {
  name: "FAQ",

  components: { Header, Icon }
};
</script>
