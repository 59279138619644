<template>
  <div class="flex items-center">
    <!-- <router-link :to="{ name: 'issues' }" class="ml-3">
      <Icon name="sm-information-circle" class="mt-1 w-6 h-6 text-white" />
    </router-link> -->

    <div class="ml-3 w-full">
      <!-- Search -->
      <div class="relative">
        <!-- Icon -->
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon name="search" outline class="h-5 w-5 text-gray-500"/>
        </div>

        <!-- Loader -->
        <div v-if="form.loading" class="absolute inset-y-0 right-0 pr-4 flex items-center">
          <Loader :size="20" />
        </div>

        <!-- Search Input -->
        <input @input="debounceSearch" @focus="form.hasFocus = true" @blur="debounceBlur"
          class="block w-full pl-10 py-3 pr-10 bg-gray-700 rounded-md text-sm placeholder-gray-300 text-gray-200 tracking-wider"
          placeholder="Search..." />
      </div>

      <!-- Results Panel -->
      <div v-show="showResults" class="absolute mt-4 z-20" :class="width">
        <div class="bg-white overflow-hidden shadow-lg border border-t-0 rounded-b-md">
          <div class="px-4 py-5 sm:p-6 flex flex-col justify-between grid gap-10" :class="columns">
            <!-- Product Results -->
            <div v-if="results.products.length > 0">
              <h3 class="text-sm font-bold text-gray-600 uppercase mb-3">Product Catalog</h3>

              <div class="-ml-2 grid grid-cols-1 gap-2">
                <router-link :to="`/products/${slugify(product.name)}`" v-for="product in results.products" :key="product.name" @click.native="$emit('select', product)" class="block p-3 rounded-md flex items-center justify-between text-gray-800 hover:bg-blue-100 mt-2 first:mt-0">
                  <div class="flex items-center">
                    <img class="w-8 h-8" :src="require(`../assets/logos/${product.logo}.svg`)" />
                    <div class="ml-4">
                      <h3 class="text-sm">{{ product.name }}</h3>
                      <p class="text-xs text-gray-600">{{ product.description }}</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <!-- Issues Results -->
            <!-- <div v-if="results.issues.length > 0">
              <h3 class="text-sm font-bold text-gray-600 uppercase mb-3 flex items-center justify-between">
                <span>Issue Tracker</span>
                <router-link :to="{ name: 'issues' }" v-slot="{ navigate }">
                  <Button size="xs" @click="navigate">See All</Button>
                </router-link>
              </h3>

              <router-link :to="{ name: 'issues' }" v-for="issue in results.issues" :key="issue.id" @click.native="$emit('select', issue)" class="group block p-3 rounded-md hover:bg-blue-100">
                <div class="flex items-start">
                  <Icon :name="getBadgeIcon(issue)" class="w-8 h-8 flex-shrink-0" :class="getIssueClasses(issue)" outline/>

                  <div class="ml-3">
                    <p class="text-sm text-gray-600 group-hover:text-gray-800 mb-2">{{ issue.title }}</p>

                    <div>
                      <Badge class="mr-1 mb-1" v-for="(badge, i) in issue.badges" :key="i" :tone="badge.tone">{{ badge.text }}</Badge>
                    </div>
                  </div>
                </div>
              </router-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import { slugify } from '@/behaviors/utils'
import { Loader } from '@/components'
import Icon from 'vue-heroicon-next'

export default {
  name: 'Omnisearch',

  components: { Icon, Loader },

  props: {
    products: {
      type: Array
    },

    issues: {
      type: Array
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        search: '',
        hasFocus: false,
        loading: false,
      }
    }
  },

  computed: {
    columns() {
      const results = this.results

      if (results.products.length > 0 && results.issues.length > 0) {
        return 'grid-cols-2'
      } else {
        return 'grid-cols-1'
      }
    },

    width() {
      if (this.columns === 'grid-cols-2') {
        return 'max-w-3xl'
      } else {
        return 'max-w-lg'
      }
    },

    results() {
      if (this.form.search.length === 0) {
        return {
          products: [],
          issues: []
        }
      }

      return {
        products: this.search(this.form.search, this.products, ['name', 'description']),
        issues: this.search(this.form.search, this.issues, ['title', 'type', 'badges.text'])
      }
    },

    length() {
      return this.form.search.length
    },

    showResults() {
      return this.form.hasFocus && this.hasResults
    },

    hasResults() {
      return this.results.products.length > 0 || this.results.issues.length > 0
    }
  },

  methods: {
    slugify,

    /**
     * The search functionality in this component is completely client
     * side. We actually simulate 150ms of "latency" to create some depth
     * in the search experience. Without this, it's almost like the results
     * are _too_ snappy and it feels somewhat jarring.
     *
     * This is _certainly_ polish and not necessary, but it adds a nice effect.
     * Implementation-wise, we're manually implementing two-way data binding so
     * that we can subscribe to @input to simulate our latency.
     */
    debounceSearch(event) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }

      this.form.loading = true

      this.debounce = setTimeout(() => {
        this.form.loading = false
        this.form.search = event.target.value
      }, 150)
    },

    /**
     * When focus is lost on search bar, we de-bounce focus for a short amount
     * of time in case the user has clicked something in results. An alternative,
     * more complex implementation might be to implement a click-outside directive
     * and filter the results containers from that.
     */
    debounceBlur(event) {
      setTimeout(() => {
        this.form.hasFocus = false
      }, 200)
    },

    search(needle, haystack, keys = [], results = 3) {
      const fuse = new Fuse(haystack, {
        keys,
        threshold: 0.2,
      })

      return fuse.search(needle)
        .map(hit => hit.item)
        .slice(0, results)
    },

    hasBadgeNamed(issue, type) {
      return issue.badges.filter(b => b.text === type).length > 0
    },

    getBadgeIcon(issue) {
      if (this.hasBadgeNamed(issue, 'Feature Request')) {
        return 'light-bulb'
      } else if (this.hasBadgeNamed(issue, 'Bug')) {
        return 'emoji-sad'
      } else {
        return 'question-mark-circle'
      }
    },

    getIssueClasses(issue) {
      if (this.hasBadgeNamed(issue, 'Feature Request')) {
        return 'text-yellow-500'
      }

      if (this.hasBadgeNamed(issue, 'Bug')) {
        return 'text-red-500'
      }

      return 'text-gray-400'
    }
  },
}
</script>
