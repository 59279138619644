import { createStore } from 'vuex'

import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import ui from './modules/ui.js'
import rest from './modules/rest.js'

import VuexPersistence from 'vuex-persist'


const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
})

const store = createStore({
    state,
    mutations,
    actions,
    modules: { ui, rest },
    plugins: [ vuexLocal.plugin ]
})

export default store
