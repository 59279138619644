<template>
  <div>
    <button class="relative inline-flex items-center border border-transparent font-medium rounded focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-75 text-white bg-blue-700 focus:border-blue-300 hover:bg-blue-600 active:bg-blue-800 text-sm">
      <div>
        <div @click="$emit('launch', buttonValues[0])" class="pl-4 py-2" >
          <p v-if="buttonValues[0].comboButtonLabel" class="truncate">{{buttonValues[0].comboButtonLabel}}</p>
          <p v-else class="truncate">Launch</p>
        </div>
      </div>
      <div class="pr-4 py-2">
        <Loader v-if="buttonLoading(buttonValues[0].id)" :size="14" class="ml-3" />
        <Icon v-else @click.native="open = !open"  :name="open ? 'chevron-up' : 'chevron-down'" class="ml-2 -mr-2 h-5 w-5" />
      </div>
    </button>

    <!-- OVERLAY TO CLOSE DROPDOWN CLICKING OUTSIDE -->
    <div v-if="open" @click="open = false" type="button" class="block fixed inset-0 w-full h-full cursor-default"></div>

    <div v-if="open" class="origin-top-right absolute  mt-2 rounded-md shadow-lg">
      <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
        <div class="py-1 max-h-48 overflow-y-scroll">
          <button @click="$emit('launch', button)" v-for="button in otherButtonValues" :key="button.id"  class="flex px-4 py-2 w-full text-right text-sm leading-5 font-semibold whitespace-nowrap text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
            <p v-if="button.comboButtonLabel">{{ button.comboButtonLabel }}</p>
            <p v-else>{{ button.title }}</p>
            <Loader v-if="buttonLoading(button.id)" :size="14" class="ml-3 self-center" color="#566573 "/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from 'vue-heroicon-next'
  import Loader from '@/components/core/Loader'

  export default {
    name: 'ComboButton',
    props: [ 'buttonValues', 'loading' ],
    components: { Icon, Loader },
    data() {
      return {
        open: false
      }
    },

    computed: {
      otherButtonValues() {
        return this.buttonValues.slice(1)
      }
    },

    methods: {
      buttonLoading(id) {
        if (!this.loading) {
          return false
        }
        return this.loading.id === id
       },
    },
  }
</script>

