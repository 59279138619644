<template>
  <div class="bg-white shadow rounded-lg">
    <!-- Header -->
    <div v-if="hasHeader" class="border-b border-gray-100 px-4 py-5 sm:px-6">
      <slot name="header"></slot>
    </div>

    <!-- Content -->
    <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
      <slot></slot>
    </div>

    <!-- Footer -->
    <div v-if="hasFooter" class="bg-gray-100 px-4 py-4 sm:px-6">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',

  computed: {
    hasHeader() {
      return this.$slots.header
    },

    hasFooter() {
      return this.$slots.footer
    }
  }
}
</script>
