export default {
  namespaced: true,

  state: {
    snackbar: {
      show: false,
      tone: 'info',
      position: 'bottom',
      message: '',
      timeout: 3000
    }
  },

  mutations: {  
    showSnackbar(state, payload) {
      if (!payload.message) {
        console.error('You MUST provide a message when using the Snackbar.')
        return
      }

      if (payload.timeout && payload.timeout < 0) {
        console.error('Negative Snackbar timeouts are silly.')
      }

      if (payload.tone) {
        state.snackbar.tone = payload.tone
      }

      if (payload.timeout !== undefined) {
        state.snackbar.timeout = payload.timeout
      } else {
        state.snackbar.timeout = 3000
      }

      if (payload.position) {
        state.snackbar.position = payload.position
      } else {
        state.snackbar.position = 'bottom'
      }

      state.snackbar.show = payload.show === undefined ? true : payload.show
      state.snackbar.message = payload.message
    },

    dismiss(state) {
      state.snackbar.show = false
    }
  }
}