<template>
  <img :src="src" />
</template>

<script setup>
  import { ref, watchEffect, defineProps } from 'vue'

  const assets = {}

  function importAll(r) {
    r.keys().forEach((key) => (assets[key.replace('./', '@/assets/')] = r(key)))
  }

  importAll(require.context('../assets/', true, /\.(png|jpg)$/))

  const props = defineProps({
    src: String
  })

  const src = ref()

  watchEffect(async () => {
    src.value = assets[props.src]
  })
</script>
