import axios from 'axios'

const client = axios.create({
  baseURL: `https://${process.env.VUE_APP_DEMO_HOSTNAME}`,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

export default client
