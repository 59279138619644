<template>
  <span :class="[classes.all, classes[tone]]">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'Badge',

  props: {
    tone: {
      type: String,
      validator: (val) => ['neutral', 'info', 'caution', 'critical', 'positive'].includes(val),
      default: 'neutral'
    },
  },

  data() {
    return {
      classes: {
        all: 'inline-flex items-center px-3 py-1 rounded-full text-xs font-medium leading-4 whitespace-no-wrap',
        neutral: 'bg-gray-200 text-gray-800',
        info: 'bg-blue-200 text-blue-800',
        caution: 'bg-yellow-200 text-yellow-800',
        critical: 'bg-red-200 text-red-800',
        positive: 'bg-green-200 text-green-800',
        attention: 'bg-purple-200 text-purple-800'
      }
    }
  },

  mounted() {
    if (this.$slots.default === undefined) {
      console.error("Badge component requires slot content.")
    }
  }
}
</script>
