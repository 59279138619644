import store from '@/js/store/store'
import router from '@/js/router'
import api from '@/services/api'

/**
 * Launch a product.
 *
 * Responsible for Vuex state management and page navigation behaviours
 * associated with launching a product.
 *
 * Where is this used?
 *
 * This is used in the Content, FooterMenu and Live components.
 *
 * @param {*} product product config from $store.state.demoTiles
 */
export async function launch(product) {
  try {
    // Guacamole
    store.commit('MUTATESTATE', { key: 'hasGuac', value: product.serviceName === 'guacamole' })
    if (product.serviceName === 'guacamole') {
      store.commit('MUTATESTATE', { key: 'guacSelected', value: product.subService })
    }

    // Set chosen product in Vuex state
    store.commit('MUTATESTATE', { key: 'productChosenID', value: product.id })

    // Make a call to set up our proxy and return location for <iframe>
    const response = await api.post('/api/v1/selection', {
      serviceName: product.serviceName,
      protocol: product.protocol,
      subService: product.subService,
      target: product.target
    })

    const { location, bearer } = response.data

    if (bearer) {
      localStorage.setItem("authorization", bearer);
      localStorage.setItem("useToken", true);
    }

    if (location) {
      // Beam is launched in another window. We don't host this.
      if (product.serviceName !== 'beam') {
        store.commit('MUTATESTATE', { key: 'iframeSource', value: location })

        // This is there to force <iframe> to re-render.
        store.commit('MUTATESTATE', { key: 'selectCounter', value: ++store.state.selectCounter })

        if (router.currentRoute.name !== 'live') {
          router.push({ name: 'live' })
        }
      } else {
        window.open(`${location}`, '_blank')

        if (router.currentRoute.name !== 'landing') {
          router.push({ name: 'landing' })
        }
      }

      return true
    }

    return false
  } catch (error) {
    console.error(error)
  }
}
