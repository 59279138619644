<template>
  <div>
    <Header />
    <div class="max-w-4xl mx-auto py-10">
        <h2 class="mb-6 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
            Account Settings
        </h2>

        <div class="mb-8">
          <FieldGroup title="Session Timer" description="Shows a timer that tracks the duration of your user session.">
            <div class="mt-4 ml-1">
              <!-- Possible CheckboxField -->
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="headerTimer" id="offers" type="checkbox" class="h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                </div>
                <div class="ml-1 pl-7 text-sm leading-5">
                  <label for="offers" class="font-medium text-gray-700">Show timer in Header</label>
                </div>
              </div>
            </div>
            <div class="mt-4 ml-1">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="footerTimer" id="offers" type="checkbox" class="h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                </div>
                <div class="ml-1 pl-7 text-sm leading-5">
                  <label for="offers" class="font-medium text-gray-700">Show timer in Footer</label>
                </div>
              </div>
            </div>
          </FieldGroup>
        </div>

        <div class="mb-8">
          <FieldGroup title="Prism Language Settings" description="The language settings and string formatting in Prism and Prism Central will be changed to match your selections below.">
            <!-- Language -->
            <div class="mt-6">
              <!-- Language -->
              <div>
                <label for="language" class="block text-sm font-medium leading-5 text-gray-700">Language</label>
                <div class="relative inline-block mt-2">
                  <select v-model="selectedLanguage" @change="matchLanguageRegion" class="block appearance-none bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="region">
                    <option v-for="language in languages" :key="language.value" :value="language.value">
                      {{ language.language }}
                    </option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <Icon name="chevron-down" outline class="h-5 w-5"/>
                  </div>
                </div>
              </div>

              <!-- Region -->
              <div class="mt-5">
                <label for="region" class="block text-sm font-medium leading-5 text-gray-700">Region-specific Formatting</label>
                <div class="mt-2">
                  <div class="relative inline-block">
                    <select v-model="selectedRegion" class="block appearance-none bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="region">
                      <option v-for="region in regions" :key="region.value" :value="region.value">
                        {{ region.name }}
                      </option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <Icon name="chevron-down" outline class="h-5 w-5"/>
                    </div>
                  </div>

                  <!-- Current Time -->
                  <p class="text-sm mt-2 text-gray-600">Regional time is {{ regionTime }}</p>
                </div>
              </div>
            </div>
          </FieldGroup>
        </div>

        <div>
          <Button color="blue" @click="saveSettings">Save Changes</Button>
        </div>
    </div>
  </div>
</template>

<script>
import { FieldGroup, Header, Button } from "@/components"
import Icon from 'vue-heroicon-next'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AccountSettings',

  components: { Header, FieldGroup, Icon, Button },

  data() {
    return {
      locale: moment.locale()
    }
  },

  computed: {
    ...mapState([
      "supportedLanguages",
      "supportedRegions"
    ]),
    headerTimer: {
      get () {
        return this.$store.state.user.settings.timerInHeader
      },
      set (value) {
        this.$store.commit('TOGGLEHEADERTIMER', value)
      }
    },
    footerTimer: {
      get () {
        return this.$store.state.user.settings.timerInFooter
      },
      set (value) {
        this.$store.commit('TOGGLEFOOTERTIMER', value)
      }
    },
    languages() {
      return this.$store.state.supportedLanguages
    },
    selectedLanguage: {
      get() {
        return this.$store.state.user.settings.chosenPrismLanguage
      },
      set(val) {
        this.$store.commit('CHANGEPRISMLANGUAGE', val)
      }
    },
    regions() {
      return this.$store.state.supportedRegions
    },
    selectedRegion: {
      get() {
        return this.$store.state.user.settings.chosenPrismRegion
      },
      set(val) {
        this.$store.commit('CHANGEPRISMREGION', val)
      }
    },
    regionTime() {
      return moment(new Date()).locale(this.selectedRegion).format("LL, LTS")
    }
  },

  methods: {
    ...mapActions(['sendUserSettingsUpdate']),
    saveSettings() {
      this.sendUserSettingsUpdate()
    },
    matchLanguageRegion() {
      if (this.regions.filter(r => r.value == this.selectedLanguage).length) {
        this.selectedRegion = this.selectedLanguage
      }
    }
  }
}
</script>
