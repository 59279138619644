import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import NProgress from 'nprogress'

NProgress.configure({
  showSpinner: false
})

//Import Main Views and Components
import Landing from '@/views/Landing.vue'
import AccountSettings from '@/views/AccountSettings.vue'
import Live from '@/views/Live.vue'
import Login from '@/views/Login.vue'
// import IssueTracker from '@/views/IssueTracker.vue'
import FAQ from '@/views/FAQ.vue'
import Content from '@/views/Content.vue'

const checker = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': `https://${process.env.VUE_APP_DEMO_HOSTNAME}:8081`
  }
})

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing
    },
    {
      path: '/settings',
      name: 'settings',
      component: AccountSettings,
      meta: {
        auth: true
      }
    },
    {
      path: '/live',
      name: 'live',
      component: Live,
      meta: {
        auth: true
      }
    },
    // {
    //   path: '/issue-tracker',
    //   name: 'issues',
    //   component: IssueTracker,
    //   meta: {
    //     auth: true
    //   }
    // },
    {
      path: '/faq',
      name: 'faq',
      component: FAQ,
      meta: {
        auth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'content',
      component: Content,
      props: route => ({
        path: route.path
      }),
      meta: {
        auth: true
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return { x: 0, y: 0 }
  }
})
router.beforeEach((toRoute, fromRoute, next) => {
  if (toRoute.matched.some(record => record.meta.auth)) {
    checker.get('/checkSession')
      .then(response => {
        if (response.status == 200) {
          next()
        }
        else {
          next({ name: 'login' })
        }
      })
      .catch(err => {
        console.error(`Navigation failed with error: ${err}`)
        next({ name: 'login' })
      })
  }
  else if (toRoute.fullPath === '/') {
    checker.get('/checkSession')
      .then(response => {
        if (response.status == 200) {
          next()
        }
        else {
          next({ name: 'login' })
        }
      })
      .catch(err => {
        next({ name: 'login' })
      })
  }
  else {
    next()
  }
})

// Page navigation progress bars.
router.beforeResolve((to, from, next) => {
  if (to.name) {
      NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
