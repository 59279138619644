<template>
  <div v-if="visible" class="flex items-center bg-gray-900 py-2 px-3 space-x-4 sm:space-x-8 text-white">
    <div class="flex-1 flex items-center justify-between">
      <div class="flex items-center space-x-2">
        <Icon name="beaker" outline class="flex-shrink-0 w-5 h-5" />
        <p class="hidden sm:block font-mono text-xs">
          {{ environment }}
        </p>
      </div>

      <div class="flex items-center space-x-2">
        <Icon name="code" outline class="flex-shrink-0 w-5 h-5" />
        <p class="hidden sm:block font-mono text-xs">
          {{ branch }} / {{ commit }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'vue-heroicon-next'

export default {
  name: 'BuildInformation',

  components: { Icon },

  data() {
    return {
      branch: process.env.VUE_APP_CI_BRANCH_NAME,
      commit: process.env.VUE_APP_CI_COMMIT_SHORT_SHA,
      environment: process.env.VUE_APP_CI_ENVIRONMENT_NAME
    }
  },

  computed: {
    visible() {
      return process.env.VUE_APP_SHOW_BUILD == 'true'
    }
  }
}
</script>
