import { createApp } from 'vue'
import App from './App.vue'
import store from './js/store/store'
import router from './js/router'
// import LoadScript from 'vue-plugin-load-script'
import './assets/css/app.css'

const app = createApp(App)
app.use(store)
app.use(router)
// app.use(LoadScript)


// vue-tippy
import VueTippy from "vue-tippy"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/light-border.css"
app.use(VueTippy, {
  arrow: true
})


import VueGtag from 'vue-gtag-next'
app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS || "G-MDDF2GNX57" // Default to NGD Dev
  }
}, router);

// Directives
app.directive('sizing', function (el, binding){
  let columns = binding.value
  el.style.display = 'grid'
  el.style.gridTemplateColumns = columns
})

let handleOutsideClick
app.directive('closable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          clickedOnExcludedEl = e.target
        }
      })
      if (vnode.context.menuToggle == false) {
        return
      }
      else {
        vnode.context[handler]()
      }
    }
    // Register click event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
  },

  unbind () {
    // If the element that has v-closable is removed,
    document.removeEventListener('click', handleOutsideClick)
  }
})

app.mount('#app')
