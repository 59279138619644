<template>
  <div>
    <!-- Hazy wonderland -->
    <div v-show="menuToggle" class="absolute inset-0 bg-semi-75 z-40" @click="toggle"></div>

    <!-- Footer Bar -->
    <div class="fixed bottom-0 right-0 z-50 w-full h-28 flex flex-row items-center justify-between" :class="{ 'bg-gray-100 pointer-events-auto': menuToggle, 'pointer-events-none': !menuToggle }">
      <!-- Floating Action Button -->
      <div class="flex items-center order-3 mr-5 pointer-events-auto" :class="{ 'absolute bottom-0 right-0 mb-7': !menuToggle }">
        <div v-show="menuToggle" @click="signUserOut" class="flex items-center cursor-pointer text-gray-700 hover:text-gray-500 mr-10 text-lg">
          <Icon name="logout" outline class="w-8 h-8 mr-2" /> Sign Out
        </div>
        <button @click.stop="toggle" class="p-4 rounded-full shadow-lg focus:outline-none" :class="fabClasses" :content="footerTippy" v-tippy>
          <div class="w-6 h-6 text-white">
            <Icon v-if="!menuToggle" name="menu" outline />
            <Icon v-else name="x" outline />
          </div>
        </button>
      </div>

      <!-- Service Credentials -->
      <div v-show="menuToggle" class="items-center order-1">
        <div v-show="menuToggle" class="text-gray-800 text-lg ml-5" v-tippy content="Use these credentials to login to most services on your nested cluster.">
            <p class="font-semibold">Service Credentials:</p>
            <p>admin / nx2Tech001!</p>
        </div>
      </div>

      <div v-show="menuToggle"  class="flex flex-row items-center gap-x-5 order-2">
        <!-- Home Button -->
        <router-link :to="{ name: 'landing' }" title="Go Home">
          <div class="text-gray-700 hover:text-gray-500">
            <Icon name="home" solid class="w-10 h-10" />
          </div>
        </router-link>

        <!-- Product Selector -->
        <Dropdown class="" direction="top">
          <template #trigger="{ isOpen }">
            <div class="flex items-center justify-between w-56 py-3 pl-4 pr-2 border border-gray-200 rounded-md bg-gray-100" :class="[ isOpen ? 'shadow-inner bg-gray-200' : 'shadow-md']">
              <div class="flex items-center">
                <img class="w-6 h-6 mr-3" :src="selectedTileImage" :alt="selectedTileTitle" />
                <p class="">{{ selectedTileTitle }}</p>
              </div>
              <Icon name="selector" outline class="w-6 h-6 text-gray-600" />
            </div>
          </template>

          <template #dropdown="{ toggle }">
            <div class="mt-3 bg-white xl:border rounded-md overflow-hidden w-56 whitespace-no-wrap shadow-lg">
              <div v-for="(product, index) in availableTiles" :key="index" @click="() => { toggle(); changeProduct(product) }"
                class="m-2 px-3 py-2 rounded text-gray-800 hover:bg-gray-200 cursor-pointer flex items-center"
                :class="{ 'shadow-inner bg-blue-200': product.title == selectedTileTitle }">
                <img class="w-8 h-8 mr-4" :src="product.img" :alt="product.title" />
                <p class="text-sm">{{ product.title }}</p>
              </div>
            </div>
          </template>
        </Dropdown>

        <!-- Request Cluster Button/Session Timer -->
        <div v-show="showFooterTimer && hasTarget">
          <SessionTimer :expires="expires" :warn="30" :crit="10" @crit="notifyUserSessionEnding" />
        </div>
        <div v-show="!hasTarget">
          <Button color="blue" @click.prevent="sendClusterRequest" :loading="requestingCluster">Request Cluster</Button>
        </div>
      </div>
    </div>
    <!-- Footer Bar End -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import moment from "moment-timezone";
import Cookie from 'js-cookie'
import Icon from 'vue-heroicon-next'

import { Dropdown, Button } from '@/components'
import SessionTimer from '@/components/SessionTimer'
import { launch } from '@/behaviors/launcher'
import snackbar from '@/behaviors/snackbar'

export default {
  name: 'FooterMenu',

  components: { Dropdown, Icon, SessionTimer, Button },

  data() {
    return {
      expires: moment(Cookie.get('timer'), "x").format(),
      menuToggle: false,
      slackChannel: `https://slack.com/app_redirect?channel=${this.$store.state.slackChannel}`,
      tenMinuteWarning: false,
      requestingCluster: false
    };
  },
  computed: {
    ...mapState(["demoTiles", "productChosenID", 'user', 'noClusterRequired']),

    selectedTile() {
      let filteredTiles = this.$store.state.demoTiles.filter(d => {
        return d.id === this.$store.state.productChosenID;
      });
      return filteredTiles[0];
    },

    selectedTileTitle() {
      return this.selectedTile.title || "Oops!";
    },

    selectedTileImage() {
      return this.selectedTile.img || "/img/Nutanix-AHV.3778d777.svg";
    },

    fabClasses() {
      return this.tenMinuteWarning ? 'bg-red-700' : (this.menuToggle ? 'bg-gray-800' : 'bg-green-700')
    },

    footerTippy() {
      return this.menuToggle ? 'Hide Demo Menu' : 'Show Demo Menu'
    },

    availableTiles() {
      if (!this.user.hasCluster) {
        return this.demoTiles.filter(dt => this.noClusterRequired.includes(dt.id))
      }
      return this.demoTiles
    },

    showFooterTimer() {
      return this.user.settings.timerInFooter
    },

    hasTarget() {
       return this.user.hasCluster
    }
  },
  methods: {
    ...mapActions(["EventServiceUserLogout", "requestCluster"]),

    toggle() {
      this.menuToggle = !this.menuToggle
    },

    notifyUserSessionEnding() {
      this.tenMinuteWarning = true
    },

    changeProduct(product) {
      // Clean up cookies from read only services
      document.cookie = "pcReadOnly=false"
      document.cookie = "vCenterReadOnly=false"

      launch(product).then(() => {
        // Report product usage to Google Analytics
        this.$gtag.event(`${product.serviceName}_${product.subService}`)
      }).catch(error => {
        if (error.response.status == 401) {
          this.$emit("sessionTimeout");
        } else {
          this.$emit("launchError");
        }
      }).finally(() => {
        this.menuToggle = false
      })
    },

    signUserOut() {
      this.EventServiceUserLogout()
      snackbar.dismiss()
      this.$router.push({ name: 'login' })
      this.$gtag.event('logout')
    },

    async sendClusterRequest() {
      this.requestingCluster = true
      try {
        await this.requestCluster()
      }
      catch(err) {
        console.error(err)
      }
      finally {
        this.requestingCluster = false
      }
    },
  }
};
</script>
