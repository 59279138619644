<template>
  <div id="particles" class="w-full h-full"></div>
</template>

<script>
require('particles.js')

export default {
  name: 'ParticleField',

  props: {
    particles: {
      type: Number,
      default: 50
    }
  },

  computed: {
    /**
     * This only exists to determine when to re-render the particle
     * field upon props changes. It allows us to aggregate all things
     * we want to "watch" rather than add N number of watchers.
     */
    requiresReRender() {
      return [this.particles]
    }
  },

  watch: {
    requiresReRender() {
      this.render()
    }
  },

  mounted() {
    this.render()
  },

  methods: {
    render() {
      this.$nextTick(() => {
        /* eslint-disable-next-line */
        particlesJS('particles', {
          "particles": {
            "number": {
              "value": this.particles,
              "density": {
                "enable": true,
                "value_area": 800
              }
            },
            "color": {
              "value": "#ffffff"
            },
            "shape": {
              "type": "circle",
              "stroke": {
                "width": 0,
                "color": "#000000"
              }
            },
            "opacity": {
              "value": 0.5,
              "random": false,
              "anim": {
                "enable": false,
                "speed": 1,
                "opacity_min": 0.1,
                "sync": false
              }
            },
            "size": {
              "value": 2,
              "random": true,
              "anim": {
                "enable": false,
                "speed": 40,
                "size_min": 0.1,
                "sync": false
              }
            },
            "line_linked": {
              "enable": true,
              "distance": 150,
              "color": "#ffffff",
              "opacity": 0.4,
              "width": 1
            },
            "move": {
              "enable": true,
              "speed": 6,
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
              }
            }
          },
          "interactivity": {
            "detect_on": "canvas",
            "events": {
              "onhover": {
                "enable": true,
                "mode": "repulse"
              },
              "resize": true
            },
            "modes": {
              "grab": {
                "distance": 400,
                "line_linked": {
                  "opacity": 1
                }
              },
              "bubble": {
                "distance": 400,
                "size": 40,
                "duration": 2,
                "opacity": 8,
                "speed": 3
              },
              "repulse": {
                "distance": 200
              },
              "push": {
                "particles_nb": 4
              },
              "remove": {
                "particles_nb": 2
              }
            }
          },
          "retina_detect": true
        })
      })
    }
  }
}
</script>
