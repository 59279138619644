<!--

# Snackbar

Snackbars are little notifications that show up at the top or
bottom of the screen when something happens.

Typically, they'll have some Alert-esque styling with text and
an optional call to action.

-->

<template>
  <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-100 ease-in"
      :enter-from-class="position === 'bottom' ? 'opacity-0 translate-y-20': 'opacity-0 -translate-y-20'"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      :leave-to-class="position === 'bottom' ? 'opacity-0 translate-y-20': 'opacity-0 -translate-y-20'"
    >
    <div v-show="modelValue" class="z-50 transform absolute w-full flex justify-center" :class="position === 'bottom' ? 'bottom-0 mb-4' : 'top-0 mt-4'">
      <div :class="[classes.all, classes[tone]]">
        <slot></slot>
        <button @click="() => { hide(); $emit('dismiss') }" >
          <Icon name="x" outline v-if="dismissable" class="w-6 h-6 cursor-pointer" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from 'vue-heroicon-next'

export default {
  name: 'Snackbar',

  components: { Icon },

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },

    tone: {
      type: String,
      validator: (val) => ['info', 'caution', 'critical', 'positive'].includes(val),
      default: 'info'
    },

    position: {
      type: String,
      validator: (val) => ['top', 'bottom'].includes(val),
      default: 'bottom'
    },

    timeout: {
      type: Number,
      default: 0
    },

    dismissable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      classes: {
        all: 'max-w-4xl w-full px-5 py-4 flex items-center justify-between border rounded shadow-lg tracking-wide',
        info: 'bg-blue-600 border-transparent text-white',
        caution: 'bg-orange-600 border-transparent text-white',
        critical: 'bg-red-600 border-transparent text-white',
        positive: 'bg-green-600 border-transparent text-white'
      }
    }
  },

  methods: {
    show() {
      this.$emit('update:modelValue', true)
    },

    hide() {
      this.$emit('update:modelValue', false)

      if (this.timer) {
        window.clearTimeout(this.timer)
      }
    },
  },

  watch: {
    timeout(val) {
      if (this.timer) {
        window.clearTimeout(this.timer)
      }

      if (this.modelValue && this.timeout > 0) {
        this.timer = window.setTimeout(this.hide, this.timeout)
      }
    }
  },

  updated() {
    this.$nextTick(() => {
      if (this.modelValue && this.timeout > 0) {
        if (this.timer) {
          window.clearTimeout(this.timer)
        }

        this.timer = window.setTimeout(this.hide, this.timeout)
      }
    })
  }
}
</script>
