<!--

# Button

You click them every day. Any time we engage the user for a primary, secondary action
they should be using a <Button />. We also need to scope this component in those terms,
not things like padding, color.

The primary action should look primary across the application. Secondary look secondary.
-->

<template>
  <span class="rounded-md shadow-sm whitespace-no-wrap">
    <button :type="type" :disabled="disabled"
      :class="[
        classes.all,
        classes[color],
        classes.size[size],
        loading ? classes.loading : '',
        disabled ? classes.disabled : '',
        full ? 'w-full justify-center': 'inline-flex'
      ]">
      <slot></slot>
      <Loader v-if="loading" :size="14" class="ml-3" />
    </button>
  </span>
</template>

<script>
import Loader from '@/components/core/Loader'

export default {
  name: 'Button',

  components: { Loader },

  props: {
    type: {
      type: String,
      validator: (val) => ['button', 'submit', 'reset'].includes(val),
      default: 'button'
    },

    color: {
      type: String,
      validator: (val) => ['red', 'blue', 'white'].includes(val),
      default: 'white'
    },

    size: {
      type: String,
      validator: (val) => ['xs', 'base', 'xl'].includes(val),
      default: 'base'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    full: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      classes: {
        all: 'inline-flex items-center border border-transparent font-medium rounded focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-75',
        blue: `text-white bg-blue-700 focus:border-blue-300 ${!this.disabled ? 'hover:bg-blue-600 active:bg-blue-800' : ''}`,
        red: `text-white bg-red-700 focus:border-red-300 ${!this.disabled ? 'hover:bg-red-600 active:bg-red-800' : ''}`,
        white: `text-gray-700 bg-white focus:bg-gray-200 ${!this.disabled ? 'hover:bg-gray-100 active:text-gray-800 active:bg-gray-200': ''}`,
        loading: 'opacity-50 cursor-not-allowed',
        size: {
          xs: 'px-2 py-1 text-xs',
          base: 'px-4 py-2 text-sm',
          xl: 'px-6 py-3 text-base'
        },
        disabled: 'opacity-50 cursor-not-allowed',
      }
    }
  }
}
</script>
