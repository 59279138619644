import api from './api'

export async function index() {
  const response = await api.get(`/api/v1/issues`)

  return response.data
}

export async function show(id) {
  const response = await api.get(`/api/v1/issues/${id}`)

  return response.data
}

export async function create(formData) {
  // const formResponse = JSON.stringify(formData)

  return await api.post(`/api/v1/issues/`, formData)

  // return response.data
}

export default {
  index, show, create
}