<template>
  <div class="grid" :class="['grid-cols-1', classes.cols[columns], classes.gapX[gap]]">
    <div v-for="(col, i) in columns" :key="i" :class="classes.gapY[gap]">
      <div v-for="(brick, j) in results[i]" :key="j">
        <slot :brick="brick"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bricks: {
      type: Array,
      required: true
    },

    columns: {
      type: Number,
      validator: (val) => [2, 3, 4, 5].includes(val),
      default: 2
    },

    gap: {
      type: Number,
      validator: (val) => [6, 4].includes(val),
      default: 6
    }
  },

  data() {
    return {
      classes: {
        cols: {
          2: 'md:grid-cols-2 gap-4',
          3: 'md:grid-cols-3 gap-4',
          4: 'md:grid-cols-4 gap-4',
          5: 'md:grid-cols-5 gap-4'
        },
        gapX: {
          4: 'gap-4',
          6: 'gap-6'
        },
        gapY: {
          4: 'space-y-4',
          6: 'space-y-6'
        }
      }
    }
  },

  computed: {
    /**
     * This guy's job is to take our bricks from the parent and we're
     * going to sort them into an array of buckets, where the number of
     * buckets matches the number of columns specified via prop.
     */
    results() {
      // We need an initial value for our accumulator, so this is
      // an "columns-sized" array of "buckets" (which are arrays)
      let initialValue = Array.from(Array(this.columns), () => [])

      /**
       * The magic happens... ;)
       *
       * So, this basically uses modulo to wrap bricks when
       * we reach max columns so that bricks are evenly sorted
       * left-to-right, top-to-bottom.
       */
      let bucket = 0
      return this.bricks.reduce((accum, result) => {
        accum[bucket++ % this.columns].push(result)

        return accum
      }, initialValue)
    }
  }
}
</script>
