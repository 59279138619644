<template>
  <div class="min-h-screen bg-white flex">
    <div class="py-12 px-4 px-24">
      <div class="mx-auto w-full max-w-sm">
        <div>
          <!-- Logo -->
          <div class="h-5">
            <a href="https://www.nutanix.com">
              <img class="h-full " src="../assets/logos/Nutanix_color.png" alt="Nutanix logo" />
            </a>
          </div>

          <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
            Sign in to Next-Gen Demo
          </h2>

          <div v-show="false"  class="mt-5">
            <Notice tone="promote"> <!-- Notice text would go here --> </Notice>
          </div>

          <!-- Support -->
          <!-- TODO: we should add this back once we have an in site support mechanism -->
        </div>

        <div class="mt-8">
          <div>
            <div>
              <p class="text-sm leading-5 font-medium text-gray-500">
                Employees sign in with
              </p>

              <!-- Employee Login -->
              <div class="mt-3">
                <span class="w-full inline-flex rounded-md shadow-sm">
                  <button @click="handleEmployeeLogin" type="button" class="w-full inline-flex justify-center py-2 px-4 border border-blue-600 rounded-md bg-white text-sm leading-5 font-medium text-blue-600 hover:text-blue-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
                    Okta
                  </button>
                </span>
              </div>
            </div>
          </div>

          <div class="mt-10" v-if="showPartnerLoginForm">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center text-sm leading-5">
                <span class="px-2 bg-white text-gray-500">
                  Partners
                </span>
              </div>
            </div>

            <p class="mt-3 text-sm leading-5 font-medium text-gray-500">
              Sign in via <a class="text-blue-700 underline" target="_blank" href="https://my.nutanix.com">my.nutanix.com</a>.
            </p>

            <form @submit.prevent="handlePartnerLogin" class="mt-4">
              <span class="block w-full rounded-md shadow-sm">
                <Button color="blue" :loading="form.loading" type="submit" full>Sign in</Button>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="relative w-0 flex-1 bg-blue-900">
        <img class="absolute inset-0 h-full w-full object-cover" :src="require('@/assets/misc/login-background.jpg')" alt="" />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Button, Notice } from '@/components'
import snackbar from '@/behaviors/snackbar'

export default {
  components: { Button, Notice },

  data() {
    return {
      form: {
        email: '',
        password: '',
        loading: false
      },
      slackChannel: `https://slack.com/app_redirect?channel=${this.$store.state.slackChannel}`,
      channelName: `#${this.$store.state.slackChannel}`
    };
  },

  computed: {
    showPartnerLoginForm() {
      return process.env.VUE_APP_FEATURE_ENABLE_PARTNER_AUTH === 'true'
    }
  },

  methods: {
    ...mapActions(["EventServicePartnerLogin", "fetchCurrentUser"]),

    handleEmployeeLogin() {
      snackbar.dismiss()
      window.sessionStorage.clear()
      window.location.href = '/oktaLogin'
      this.$gtag.event('login')
    },

    handlePartnerLogin() {
      window.sessionStorage.clear()
      if (process.env.VUE_APP_FEATURE_ENABLE_PARTNER_AUTH === 'true') {
        window.location.href = '/auth/portal'
      } else {
        snackbar.show({
          message: 'Partner authentication is not currently enabled.',
          tone: 'info',
          timeout: 3000
        })
      }
    }
  }
};
</script>
