<template>
  <div>
    <transition
      enter-active-class="transition-all duration-150 ease-out"
      leave-active-class="transition-all duration-100 ease-in"
      enter-from-class="opacity-0"
      enter-to="opacity-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      @afterEnter="onFullyVisible"
    >
      <div v-if="modelValue" @click="dismiss" class="fixed inset-0 flex items-center justify-center bg-semi-75 z-30">
        <transition
          enter-active-class="transition-all duration-150 ease-out"
          leave-active-class="transition-all duration-100 ease-in"
          enter-from-class="scale-90 translate-y-10"
          enter-to="scale-100"
          leave-from-class="scale-100"
          leave-to-class="scale-90 translate-y-10"
        >
          <div v-show="modelValue" @click.stop class="flex transform max-w-2xl overflow-hidden">
            <div ref="dialog" role="dialog" tabindex="-1" class="bg-white p-4 rounded-lg shadow-lg">
              <slot :actions="{ close }"></slot>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <slot name="trigger" :actions="{ show }"></slot>
  </div>
</template>

<script>
import ally from 'ally.js'

export default {
  name: 'Modal',

  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      a11y: {
        disabledElements: null,
        focusWrap: null,
        focusFirstElement: null
      }
    }
  },

  mounted() {
    document.addEventListener('keydown', this.onEscape)
  },

  watch: {
    modelValue(visible) {
      if (visible) {
        this.show()
      } else {
        this.hide()
      }
    }
  },

  beforeUnmount() {
    this.unregisterAlly()
    document.removeEventListener('keydown', this.onEscape)
  },

  methods: {
    onEscape(e) {
      if (!this.modelValue) {
        return
      }

      // If ESC is pressed
      if (e.keyCode == 27) {
        this.dismiss()
      }
    },

    show() {
      this.$emit('update:modelValue', true)
    },

    hide() {
      this.unregisterAlly()
      this.$emit('update:modelValue', false)
    },

    dismiss() {
      this.$emit('dismiss')
      this.hide()
    },

    close() {
      this.$emit('close')
      this.hide()
    },

    unregisterAlly() {
      /**
       * We undo all of the focus-trapping / a11y-related
       * work when the modal is hidden.
       */
      if (this.a11y.disabledElements) {
        this.a11y.disabledElements.disengage()
      }

      if (this.a11y.focusWrap) {
        this.a11y.focusWrap.disengage()
      }

      if (this.a11y.focusedBefore) {
        this.a11y.focusedBefore.focus()
      }
    },

    onFullyVisible() {
      /**
       * When the modal is fully visible (visual transitions have ended)
       * we focus the first "tabbable" element.
       */
      this.$nextTick(() => {
        /**
         * We make a best effort to return focus to the previous element
         * after closing the modal. So we need to track what that was.
         */
        this.a11y.focusedBefore = document.activeElement

        /**
         * We need to prevent all elements outside the dialog from being
         * focusable. This effectively "traps" focus within the dialog.
         */
        this.a11y.disabledElements = ally.maintain.disabled({
          filter: this.$refs.dialog
        })

        /**
         * We focus the first element and "wrap" focus within the document.
         * In other words, if we tab past the last element, it wraps
         * back to the first element and vice-versa. This also keeps focus
         * from jumping to browser controls while the modal is open.
         */
        this.a11y.focusWrap = ally.maintain.tabFocus({
          filter: this.$refs.dialog
        })

        let element = ally.query.firstTabbable({
          context: this.$refs.dialog,
          defaultToContext: true,
        })

        if (element) {
          element.focus()
        }
      })
    }
  }
}
</script>
