/* eslint no-inner-declarations: "off" */

import Cookie from 'js-cookie'

/**
 * Rewrite logic. This is to fixup links to other services to overwrite the default behavior to use the NGD Navigation.
 *
 * The new click handlers post a `ngdNavigationEvent` message. There is a handler on window that then launches to it.
 *
 * Code search terms: `ngd-navigation-event`, `ngdNavigationEvent`
 */
 export function performRewrite(e) {
  setupFSRewrites(e)

  performPEToPCRewrite(e)

  peformPCToPERewrite(e)

  filesConsoleRewrites(e)
}

/**
 * React uses SyntheticEvents to wrap browser events(https://reactjs.org/docs/events.html). This function replaces the
 * event function with one of our own to bypass Prism funcationality.
 *
 * @param {Element} element Element you want to replace handler on
 * @param {String} type React Event Type you want to replace handler of
 * @param {Function} newHandler New handler function to replace
 *
 */
function replaceReactSyntheticEventHandler(element, type, newHandler) {
  let reactEventHandlers
  for (let key in element) {
    /**
     * Find the event handlers construct. Pretty sure it is only 1.
     * Then replace the handler of type to newHandler
     */
    if (/reactEventHandlers/.test(key)) {
      reactEventHandlers = element[key]
      break
    }
  }
  reactEventHandlers[type] = newHandler
}

/**
 * Helper function used to determine if a given element will have the closest function.
 *
 * @param {Element} thisElement
 */
function hasClosestFunction(thisElement) {
  return !['#text', '#comment', '#document'].includes(thisElement.nodeName)
}

/**
 * Adds event listeners to FS iframe so rewrites can happen
 */
function setupFSRewrites(e) {
  let thisElement = e.target
  if (thisElement.id === 'fsIFrame' && !thisElement.classList.contains('ngd-navigation-event')) {
    const fsIFrame = thisElement
    let thisContentWindow = thisElement.contentWindow
    thisContentWindow.onmousemove = (ev) => {
        // Bubble mouse movement to document so it is picked up
        // by ActivityMonitor - This is how we detrmine when to
        // pop the are you still here message
        document.dispatchEvent(new MouseEvent("mousemove", {
          bubbles: true,
          cancelable: true,
          clientX: ev.clientX,
          clientY: ev.clientY
        }))
        performFSRewrites(ev)
      }
    thisContentWindow.onmouseover = (ev) => performFSRewrites(ev)
    thisContentWindow.onmousedown = (ev) => performFSRewrites(ev)
    fsIFrame.classList.add('ngd-navigation-event')
  }


  if (thisElement.id === 'FileServerPcIFrame' && !thisElement.classList.contains('ngd-navigation-event')) {
    const pcFSIFrame = thisElement
    let thisContentWindow = thisElement.contentWindow
    thisContentWindow.onmousemove = (ev) => {
        // Bubble mouse movement to document so it is picked up
        // by ActivityMonitor - This is how we detrmine when to
        // pop the are you still here message
        document.dispatchEvent(new MouseEvent("mousemove", {
          bubbles: true,
          cancelable: true,
          clientX: ev.clientX,
          clientY: ev.clientY
        }))
        peformPCToPERewrite(ev)
      }
    thisContentWindow.onmouseover = (ev) => peformPCToPERewrite(ev)
    thisContentWindow.onmousedown = (ev) => peformPCToPERewrite(ev)
    pcFSIFrame.classList.add('ngd-navigation-event')
  }
}

/**
 *
 * Rewrites the links for FA and FS Console
 */
function performFSRewrites(ev) {
  let thisElement = ev.target

  if (hasClosestFunction(thisElement)) {
    let fsInsightsBtn = null
    //In case the below selector doesn't work try: document.querySelector('#demoFrameBody').contentDocument.querySelector('#fsIFrame').contentDocument.querySelector('[action="view_file_insights"]')
    fsInsightsBtn = thisElement.closest('[action="view_file_insights"]')
    function navigateToFA(eve) {
      eve.stopPropagation()
      parent.parent.postMessage({event: 'ngdNavigationEvent', location: 'fileAnalytics'}, '*')
    }
    if (fsInsightsBtn && !fsInsightsBtn.classList.contains('ngd-navigation-event')) {
      fsInsightsBtn.classList.add('ngd-navigation-event')
      fsInsightsBtn.addEventListener('click', navigateToFA, false)
    }

    //Files Console Rewrite
    function navigateToFilesConsole(eve){
      eve.stopPropagation()
      const targetID = eve.target.getAttribute('actiontargetid')
      parent.parent.postMessage({event: 'ngdNavigationEvent', location: `filesConsole`, target: `${targetID}/`}, '*')
    }
    let fsConsoleBtn = null
    // Find the closest element that has the attribute we are looking for
    fsConsoleBtn = thisElement.closest('[action="open_files_console"]')
    if (fsConsoleBtn && !fsConsoleBtn.classList.contains('ngd-navigation-event')) {
      fsConsoleBtn.classList.add('ngd-navigation-event')
      fsConsoleBtn.addEventListener('click', navigateToFilesConsole, false)
    }
  }
}

function filesConsoleRewrites(ev) {
  let thisElement = ev.target
  function navigateToFA(eve) {
    eve.stopPropagation()
    parent.postMessage({event: 'ngdNavigationEvent', location: 'fileAnalytics'}, '*')
  }

  if (hasClosestFunction(thisElement)) {
    if (thisElement.textContent === 'Launch File Analytics') {
      const faAnchor = thisElement.closest("a")
      if (faAnchor && !faAnchor.classList.contains('ngd-navigation-event')) {
        faAnchor.classList.add('ngd-navigation-event')
        replaceReactSyntheticEventHandler(faAnchor, 'onClick', navigateToFA)
      }
    }

    function navigateToDR(eve) {
      eve.stopPropagation()
      parent.postMessage({event: 'ngdNavigationEvent', location: 'prismElement', target: '#page/dr/table/'}, '*')
    }

    if (thisElement.textContent === 'Manage on Prism Element') {
      const peAnchor = thisElement.closest("a")
      if (peAnchor && !peAnchor.classList.contains('ngd-navigation-event')) {
        peAnchor.classList.add('ngd-navigation-event')
        replaceReactSyntheticEventHandler(peAnchor, 'onClick', navigateToDR)
      }
    }
  }
}
/**
 * Rewrite links in PE that navigate to PC
 */
function performPEToPCRewrite(ev) {
  let thisElement = ev.target

  if (hasClosestFunction(thisElement)) {
    // Find the closest element that has the attribute we are looking for
    thisElement = thisElement.closest(".prism-central-status-widget__link,.one-click-deployment-wr")

    function navigateToPC() {
      parent.postMessage({event: 'ngdNavigationEvent', location: 'prismCentral'}, '*')
    }

    if (thisElement && !thisElement.classList.contains('ngd-navigation-event')) {
      // pc.2021.5+
      if (thisElement.classList.contains("prism-central-status-widget__link")) {
        thisElement.classList.add('ngd-navigation-event')
        replaceReactSyntheticEventHandler(thisElement, 'onClick', navigateToPC)
      }
    }
  }
}

/**
 * Rewrite links in PC that navigate to PE
 *
 * Find closest ancestor that has what we are searching for and add new event listener.
 */
function peformPCToPERewrite(ev) {
  let thisElement = ev.target

  if(hasClosestFunction(thisElement)) {
    // Find the closest element that has the attribute we are looking for
    const peLink = thisElement.closest('[action="navigate_to_pe"],[action-id="navigate_to_pe"]')

    function navigateToPE(ev) {
      // We need to stop propagation so the product listener doesn't fire
      ev.stopPropagation()
      parent.postMessage({event: 'ngdNavigationEvent', location: 'prismElement'}, '*')
    }

    let isPCReadOnly = Cookie.get('pcReadOnly')  === 'true'

    /**
     * Only do rewrite if we are on the nested PC instance
     * Element has to be found
     * Only do the rewrite it the actiontargetname is for the nested DemoCluster
     * OR
     * On the Hardware->Cluster page
     */
    if (!isPCReadOnly && peLink && !peLink.classList.contains('ngd-navigation-event')) {
      if (peLink.getAttribute('actiontargetname') === 'DemoCluster'|| peLink.getAttribute('action-id')) {
        peLink.classList.add('ngd-navigation-event')
        peLink.addEventListener('click', navigateToPE, false)
      } else if (peLink.getAttribute('actiontargetname') === 'Prism-Pro-Cluster') {
        // Remove this if ENG ever disables dashboard this link for unreachable clusters
        peLink.classList.add('ngd-navigation-event')
        peLink.addEventListener('click', function (ev) {ev.stopPropagation()}, false)
      }
    }

    function navigateToFS() {
      parent.postMessage({event: 'ngdNavigationEvent', location: 'fileServer'}, '*')
    }
    /**
     * This is ugly. The react synthetic event is immutable for this one. So I
     * create a clone of it and all its children which removes any handlers. Then
     * I replace the original with the clone and add our custom handler to it.
     * This has some performance implications for large trees.
     */
    const fsLink = thisElement.closest('.fs-manage')
    if (fsLink && !fsLink.classList.contains('ngd-navigation-event')) {
      fsLink.classList.add('ngd-navigation-event')
      let fsLinkNew = fsLink.cloneNode(true)
      fsLink.parentNode.replaceChild(fsLinkNew, fsLink)
      fsLinkNew.addEventListener('click', navigateToFS)
    }
  }
}
