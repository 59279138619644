<template>
  <div class="h-screen">
    <div class="absolute inset-0 z-0">
      <div class="h-screen flex items-center justify-center bg-gray-100">
        <Loader color="#888" :size="200" />
      </div>
    </div>

    <div class="absolute inset-0 z-10">
      <div ref="window" class="w-full h-full cursor-none" :key="selectCounter" @click.stop></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Guacamole from "@/js/guacamole-common.js";
import { mapState } from "vuex";
import { Loader } from "@/components";

let axeos = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  name: "DemoGuac",

  components: { Loader },

  data() {
    return {
      height: document.documentElement.clientHeight,
      width: document.documentElement.clientWidth,
      hostname: this.$store.state.guacSelected
    };
  },

  computed: {
    ...mapState(["selectCounter"])
  },

  mounted() {
    this.startGuacamole()
  },

  updated() {
    if (this.cleanup) {
      this.cleanup()
    }
    this.startGuacamole()
  },

  beforeUnmount() {
    this.cleanup()
  },

  methods: {
    async startGuacamole(token) {
      try {
        // Get our Guacamole token
        const token = await this.getToken()

        // Instantiate Client using WebSocket tunnel
        const tunnel = new Guacamole.WebSocketTunnel(
          `wss://${process.env.VUE_APP_DEMO_HOSTNAME}/api/v1/connectionStart`
        )

        tunnel.PING_FREQUENCY = 2000;
        tunnel.unstableThreshold = 45000;
        tunnel.receiveTimeout = 60000;

        this.guac = new Guacamole.Client(tunnel)

        // Add client to display div
        this.$refs.window.innerHTML = ''
        this.$refs.window.appendChild(this.guac.getDisplay().getElement())

        // Error handler
        this.guac.onerror = error => {
          console.error(error)
        }

        // Connect
        this.guac.connect(`token=${token}`)

        // Mouse Events
        const mouse = new Guacamole.Mouse(
          this.guac.getDisplay().getElement()
        )

        mouse.onmousedown = mouse.onmouseup = mouse.onmousemove = state => this.guac.sendMouseState(state)

        // Touchpad Events
        const touch = new Guacamole.Mouse.Touchpad(
          this.guac.getDisplay().getElement()
        )

        touch.onmousedown = touch.onmousemove = touch.onmouseup = state => this.guac.sendMouseState(state)

        // Keyboard Events
        const keyboard = new Guacamole.Keyboard(document)

        keyboard.onkeydown = key => {
          this.guac.sendKeyEvent(1, key)
        }

        keyboard.onkeyup = key => {
          this.guac.sendKeyEvent(0, key)
        }

        // Clean up ourselves when the component instance is destroyed
        this.cleanup = () => {
          this.guac.disconnect()
          delete mouse.onmousedown, mouse.onmouseup, mouse.onmousemove
          delete touch.onmousedown, touch.onmousemove, touch.onmouseup
          delete keyboard.onkeydown, keyboard.onkeyup
        }

      } catch (error) {
        this.$emit("launchError", error);
      }
    },

    async getToken() {
      const response = await axeos.post('getToken', {
        height: this.$refs.window.clientHeight,
        width: this.$refs.window.clientWidth,
        hostname: this.$store.state.guacSelected
      })

      return response.data
    }
  }
};
</script>
