<template>
  <div>
    <Header @saveHiddenCards="saveHiddenCards" @unhideAll="unhideCards" />
    <LandingCards @sessionTimeout="sessionTimeoutNotification" @launchError="timeoutNotification" ref="landingCards"></LandingCards>
  </div>
</template>

<script>
import { Header } from '@/components'
import LandingCards from '@/components/LandingCards'
import snackbar from '@/behaviors/snackbar'

export default {
  name: 'Landing',

  components: { Header, LandingCards },

  data() {
    return {
      rxFailure: false,
      sessionTimeout: false,
      sessionTimeoutID: undefined,
    }
  },

  mounted() {
    this.checkRxFailure()
  },

  beforeRouteLeave(to, from, next) {
    if (this.$store.state.configMode) {
      this.$store.commit('DISABLECONFIGMODE')
    }

    next()
  },

  computed: {
    hiddenTilesArray() {
      return this.$store.state.user.settings.hiddenTiles ? this.$store.state.user.settings.hiddenTiles : []
    }
  },

  methods: {
    unhideCards() {
      this.$refs.landingCards.unhideAllTiles()
    },
    saveHiddenCards() {
      this.$refs.landingCards.saveHiddenCards()
    },
    timeoutNotification() {
      snackbar.show({
        tone: 'critical',
        message: 'Service could not launch. Please try again.',
        position: 'top'
      })
    },
    checkRxFailure() {
      let cString = document.cookie
      if (cString.indexOf('rxFailure') > -1) {
        this.rxFailure = true

        snackbar.show({
          tone: 'critical',
          message: 'No resources are available now. Please log out, wait a few minutes, then try again.',
          timeout: 30000,
          position: 'top'
        })
      }
    },
    sessionTimeoutNotification() {
      this.sessionTimeout = true
      this.sessionTimeoutID = setTimeout(this.redirectToLogin, 30000)

      snackbar.show({
        tone: 'critical',
        message: 'You have reached max session length. You will be redirected to login shortly.',
        timeout: 29500,
        position: 'top'
      })
    },
    redirectToLogin() {
      clearTimeout(this.sessionTimeoutID)
      snackbar.dismiss()
      this.$router.push({ name: 'login'})
    }
  }
}
</script>
