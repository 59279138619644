<template>
  <div>
    <!-- Bar -->
    <div class="bg-gray-800 px-6 py-4 flex items-center justify-between">
      <div class="flex flex-1 items-center max-w-2xl xl:max-w-4xl">
        <HamburgerMenu :slackChannel="slackChannel" @toggleConfigMode="toggleConfigMode" @logout="signUserOut" />

        <!-- Logo -->
        <router-link :to="{ name: 'landing' }" class="py-4">
          <img class="h-4" src="../assets/logos/Nutanix_neg.png" alt="Nutanix logo" />
        </router-link>

        <!-- Omnisearch -->
        <div class="flex-1 ml-10">
          <Omnisearch :products="products" :issues="issues" />
        </div>
      </div>

      <div class="flex items-center">
        <!-- Config Buttons (hidden default) -->
        <div v-if="configMode" class="mr-6">
          <Button @click="saveHiddenCards" class="mr-4">Save Changes</Button>
          <Button @click="unhideAllCards">Unhide All</Button>
        </div>

        <!-- Session Timer -->
        <SessionTimer v-show="showSessionTimer" dark :expires="expires" :warn="30" :crit="10" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import moment from "moment"
import Cookie from "js-cookie"

import { Button, HamburgerMenu } from "@/components"
import Omnisearch from '@/components/Omnisearch'
import SessionTimer from '@/components/SessionTimer'
import snackbar from '@/behaviors/snackbar'

export default {
  name: 'Header',

  components: { Button, HamburgerMenu, SessionTimer, Omnisearch },

  data() {
    return {
      expires: moment(Cookie.get('timer'), "x").format(),
      menuToggle: false,
      slackChannel: `https://slack.com/app_redirect?channel=${this.$store.state.slackChannel}`,
      products: []
    }
  },

  computed: {
    ...mapState(["accountPageVisible", "configMode", "demoTiles", "user"]),
    ...mapState('rest', ['issues']),

    showSessionTimer() {
      return this.user.settings.timerInHeader && document.cookie.indexOf('target') > -1
    }
  },

  methods: {
    ...mapActions(["EventServiceUserLogout"]),

    toggleConfigMode() {
      this.$store.commit('TOGGLECONFIGMODE')
    },

    saveHiddenCards() {
      this.$emit("saveHiddenCards")
      this.toggleConfigMode()
    },

    signUserOut() {
      this.EventServiceUserLogout()
      snackbar.dismiss()
      this.$router.push({ name: 'login' })
      this.$gtag.event('logout')
    },

    unhideAllCards() {
      this.$emit("unhideAll")
    }
  },

  created() {
    this.products = this.demoTiles.map(tile => ({
      id: tile.id,
      name: tile.title,
      description: tile.description,
      logo: tile.icon
    }))

    this.$store.dispatch('rest/fetch')
  }
}
</script>
