export default {
    user: {
        username: '',
        partner: true,
        settings: {
            hiddenTiles: [],
            timerInHeader: true,
            timerInFooter: true,
            chosenPrismLanguage: 'en-US',
            chosenPrismRegion: 'en-US'
        },
        hasCluster: false,
        timer: null
    },
    monitorActivity: false,
    oktaLoginPath: process.env.VUE_APP_LOGIN_PATH,
    logoutPath: process.env.VUE_APP_LOGOUT_PATH,
    slackChannel: 'C0144AXHU9F',
    iframeSource: '',
    hasGuac: false,
    productChosenID:'',
    selectCounter: 0,
    guacSelected: '',
    // These arrays hold special values for determining how product tiles are displayed.
    noClusterRequired: [2, 12, 13, 14, 15, 16, 17],
    prismTiles: [0, 1],
    demoTiles: [
        {
            link: '',
            title: 'Prism Element',
            img: require('./../../assets/logos/Nutanix-AHV.svg'),
            icon: 'Nutanix-AHV',
            alt: 'Nutanix Prism Element',
            slackLink: 'C07NWB2A2',
            supportLink: '',
            serviceName: 'prismElement',
            protocol: 'https',
            subService: 'vip',
            instructionLink: '',
            videoLink: '',
            description: 'Native virtualization that eliminates expensive hypervisor lock-in.',
            id: 0
        },
        {
            link: '',
            title: 'Prism Central',
            img: require('./../../assets/logos/Nutanix-Prism.svg'),
            icon: 'Nutanix-Prism',
            alt: 'Nutanix Prism Central',
            slackLink: 'C0JAKR0FR',
            supportLink: '',
            serviceName: 'prismCentral',
            protocol: 'https',
            subService: 'vip',
            instructionLink: '',
            videoLink: '',
            description: 'Delightfully simple infrastructure management with one-click operations.',
            id: 1
        },
        {
            link: '',
            title: 'PC On-Demand',
            img: require('./../../assets/logos/Nutanix-Prism.svg'),
            icon: 'Nutanix-Prism',
            alt: 'Nutanix Prism Central Read Only Access',
            slackLink: 'C0144AXHU9F',
            supportLink: '',
            serviceName: 'readOnly',
            protocol: 'https',
            subService: 'pcReadOnly',
            instructionLink: '',
            videoLink: '',
            description: 'Access a read-only view of a static Prism Central instance managing both AHV and ESXi clusters.',
            id: 14,
            comboButtonLabel: 'Read Only PC'
        },
        {
            link: '',
            title: 'Read Only vCenter',
            img: require('./../../assets/logos/ESXi.png'),
            icon: 'Nutanix-Prism',
            alt: 'vCenter Read Only Access',
            slackLink: 'C0144AXHU9F',
            supportLink: '',
            serviceName: 'readOnly',
            protocol: 'https',
            subService: 'vCenterReadOnly',
            instructionLink: '',
            videoLink: '',
            description: 'Placeholder text.',
            id: 15,
            hideByDefault: true,
            comboButtonLabel: 'Read Only vCenter'
        },
        {
            link: '',
            title: 'NDB On-Demand',
            img: require('./../../assets/logos/NDB.svg'),
            icon: 'NDB',
            alt: 'NDB On-Demand',
            slackLink: 'CDDA35X6W',
            supportLink: '',
            serviceName: 'readOnly',
            protocol: 'https',
            subService: 'demoEraSim',
            instructionLink: '',
            videoLink: '',
            description: 'An on-demand demo of the Nutanix Database Service interface.',
            id: 16
        },
        {
            link: '',
            title: 'Nutanix Beam',
            img: require('./../../assets/logos/Nutanix-Beam.svg'),
            icon: 'Nutanix-Beam',
            alt: 'Nutanix Beam',
            slackLink: 'C9M6H0RJ5',
            supportLink: '',
            serviceName: 'beam',
            protocol: 'https',
            subService: 'beam',
            instructionLink: '',
            videoLink: '',
            description: 'Multicloud optimization service reduces costs and enhances cloud security compliance.',
            id: 2
        },
        {
            link: '',
            title: 'Security Central',
            img: require('./../../assets/logos/Nutanix-FlowSC.svg'),
            icon: 'Nutanix-Beam',
            alt: 'Nutanix Security Central',
            slackLink: 'C8M7J79EF',
            supportLink: '',
            serviceName: 'beam',
            protocol: 'https',
            subService: 'flow',
            instructionLink: '',
            videoLink: '',
            description: 'Gain complete visibility into the security posture of your multi-cloud environment.',
            id: 12
        },
        {
            link: '',
            title: 'NDB',
            img: require('./../../assets/logos/NDB.svg'),
            icon: 'NDB',
            alt: 'Nutanix Database Service',
            slackLink: 'CANA44ZAN',
            supportLink: '',
            serviceName: 'era',
            protocol: 'https',
            subService: 'server',
            instructionLink: '',
            videoLink: '',
            description: 'Elegant and efficient one-click operations that simplify database administration.',
            id: 3
        },
        // {
        //     link: '',
        //     title: 'Karbon',
        //     img: require('./../../assets/logos/Nutanix-Karbon.svg'),
        //     icon: 'Nutanix-Karbon',
        //     alt: 'Nutanix Karbon',
        //     slackLink: 'CCYK22E5U',
        //     supportLink: '',
        //     serviceName: 'prismCentral',
        //     protocol: 'https',
        //     subService: 'karbon',
        //     instructionLink: '',
        //     videoLink: '',
        //     description: 'Simplified provisioning, operations, and lifecycle management for Kubernetes.',
        //     id: 4
        // },
        {
            link: '',
            title: 'Files',
            img: require('./../../assets/logos/Nutanix-Files.svg'),
            icon: 'Nutanix-Files',
            alt: 'Nutanix Files',
            slackLink: 'C0C7C8D6G',
            supportLink: '',
            serviceName: 'files',
            protocol: 'https',
            subService: 'server',
            instructionLink: '',
            videoLink: '',
            description: 'Simple, scalable, and reliable file storage for your enterprise cloud.',
            id: 5
        },
        {
            link: '',
            title: 'File Analytics',
            img: require('./../../assets/logos/Nutanix-Files.svg'),
            icon: 'Nutanix-Files',
            alt: 'Nutanix File Analytics',
            slackLink: 'CBNPNM3KN',
            supportLink: '',
            serviceName: 'files',
            protocol: 'https',
            subService: 'analytics',
            instructionLink: '',
            videoLink: '',
            description: 'Insights on file data; anomaly detection and remediation of threats and risks.',
            id: 6
        },
        {
            link: '',
            title: 'Data Lens',
            img: require('./../../assets/logos/Nutanix-Files.svg'),
            icon: 'Nutanix-Files',
            alt: 'Nutanix Data Lens',
            slackLink: 'C02GQRY79J9',
            supportLink: '',
            serviceName: 'readOnly',
            protocol: 'https',
            subService: 'datalens',
            instructionLink: '',
            videoLink: '',
            description: 'Visibility and insights to manage and secure your unstructured data.',
            id: 17
        },
        {
            link: '',
            title: 'Objects',
            img: require('./../../assets/logos/Nutanix-Objects.svg'),
            icon: 'Nutanix-Objects',
            alt: 'Nutanix Objects',
            slackLink: 'C7877KJRF',
            supportLink: '',
            serviceName: 'prismCentral',
            protocol: 'https',
            subService: 'objects',
            instructionLink: '',
            videoLink: '',
            description: 'Scalable S3-compatible object storage for the multi-cloud era.',
            id: 7
        },
        {
            link: '',
            title: 'Calm',
            img: require('./../../assets/logos/Nutanix-Calm.svg'),
            icon: 'Nutanix-Calm',
            alt: 'Nutanix Calm',
            slackLink: 'C5F3QSQGG',
            supportLink: '',
            serviceName: 'prismCentral',
            protocol: 'https',
            subService: 'calm',
            instructionLink: '',
            videoLink: '',
            description: 'Comprehensive, app-centric lifecycle management and cloud orchestration.',
            id: 8
        },
        {
            link: '',
            title: 'Linux Desktop',
            img: require('./../../assets/logos/linux-nutanix.svg'),
            icon: 'linux-nutanix',
            alt: 'Nutanix Linux Dashboard',
            slackLink: null,
            supportLink: '',
            serviceName: 'guacamole',
            protocol: 'rdp',
            subService: 'linux',
            instructionLink: '',
            videoLink: '',
            description: 'Access to an ephemeral Linux Desktop Client that can reach your Demo instance.',
            id: 9
        },
        {
            link: '',
            title: 'Windows Desktop',
            img: require('./../../assets/logos/windows-nutanix.svg'),
            icon: 'windows-nutanix',
            alt: 'Nutanix Windows Dashboard',
            slackLink: null,
            supportLink: '',
            serviceName: 'guacamole',
            protocol: 'rdp',
            subService: 'windows',
            instructionLink: '',
            videoLink: '',
            description: 'Access to an ephemeral Windows Desktop that can reach your Demo instance.',
            id: 10
        },
        {
            link: '',
            title: 'Prism Pro',
            img: require('./../../assets/logos/Nutanix-Prism.svg'),
            icon: 'Nutanix-Prism',
            alt: 'Nutanix Prism Pro',
            slackLink: 'C0GRC1ZNY',
            supportLink: '',
            serviceName: 'prismPro',
            protocol: 'https',
            subService: 'vip',
            instructionLink: '',
            videoLink: '',
            description: 'Streamline infrastructure operations with the help of advanced machine intelligence.',
            id: 11
        },
        {
          link: '',
          title: 'NC2 Console',
          img: require('./../../assets/logos/Nutanix-Prism.svg'),
          icon: 'Nutanix-Prism',
          alt: 'NC2 Cluster Deployment via NC2 Console',
          slackLink: null,
          supportLink: '',
          serviceName: 'mcm',
          protocol: 'https',
          subService: 'mcm',
          instructionLink: '',
          videoLink: '',
          description: 'A simulation of cluster deployment through the Nutanix NC2 Console.',
          id: 13
      },
    ],
    accountPageVisible: false,
    configureButtonsShown: false,
    configMode: false,
    supportedLanguages: [
        {
            language: 'English',
            value: 'en-US'
        },
        {
            language: '日本語 (Japanese)',
            value: 'ja-JP'
        },
        {
            language: '简体中文 (Simplified Chinese)',
            value: 'zh-CN'
        }
        ],
        supportedRegions: [{
            name: 'Australia',
            value: 'en-AU'
        },
        {
            name: 'Belgium (Dutch)',
            value: 'nl-BE'
        },
        {
            name: 'Belgium (French)',
            value: 'fr-BE'
        },
        {
            name: 'Canada (English)',
            value: 'en-CA'
        },
        {
            name: 'Canada (French)',
            value: 'fr-CA'
        },
        {
            name: 'China',
            value: 'zh-CN'
        },
        {
            name: 'France',
            value: 'fr-FR'
        },
        {
            name: 'Germany',
            value: 'de-DE'
        },
        {
            name: 'Italy',
            value: 'it-IT'
        },
        {
            name: 'Japan',
            value: 'ja-JP'
        },
        {
            name: 'Netherlands',
            value: 'nl-NL'
        },
        {
            name: 'Russia',
            value: 'ru-RU'
        },
        {
            name: 'South Korea',
            value: 'ko-KR'
        },
        {
            name: 'Switzerland (Italian)',
            value: 'it-CH'
        },
        {
            name: 'Switzerland (French)',
            value: 'fr-CH'
        },
        {
            name: 'Switzerland (German)',
            value: 'de-CH'
        },
        {
            name: 'UAE (United Arab Emerates)',
            value: 'ar-AE'
        },
        {
            name: 'UK',
            value: 'en-GB'
        },
        {
            name: 'USA',
            value: 'en-US'
        }
    ]
}
