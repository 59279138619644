<template>
  <div>
    <div v-if="content">
      <Header />

      <div class="max-w-4xl mx-auto pt-10">
        <div>
          <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">
            <router-link :to="{ name: 'landing' }" class="text-gray-500 hover:text-gray-700 focus:outline-none focus:underline">Products</router-link>
            <Icon name="chevron-right" outline class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" />
            <p class="text-gray-500">{{ frontmatter.title }}</p>
          </nav>
        </div>

        <div class="mt-6 flex items-center justify-between border-b pb-6">
          <div class="flex items-center -ml-16">
            <img class="w-10 h-10 mr-6" :src="require(`../assets/logos/${frontmatter.icon}.svg`)" />
            <div>
              <h2 class="flex items-center text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">{{ frontmatter.title }} <Badge tone="info" class="ml-3 mt-1">Draft</Badge></h2>
              <p class="mt-1 text-gray-600">{{ frontmatter.description }}</p>
            </div>
          </div>
          <Button color="blue" @click="launchProduct(frontmatter.launcher)" :loading="loading" :disabled="!launchEnabled">Launch</Button>
        </div>
      </div>

      <!-- Markdown Content -->
      <div class="mt-8 mb-16 max-w-4xl mx-auto flex">
        <div class="max-w-xl prose" ref="markdown" >
          <component :is="content" />
        </div>
        <div class="relative ml-10 flex-1 h-auto">
          <div class="sticky top-4">
            <!-- Topics -->
            <div v-if="showTopics">
              <h3 class="font-bold text-sm tracking-wide text-gray-600 uppercase">Topics</h3>
              <div class="mt-5 grid grid-cols-1 gap-3 text-gray-600 font-medium">
                <a v-for="link in navigation" :key="link.href" :href="link.href" class="text-sm hover:text-gray-900 transition ease-in-out duration-150">
                  {{ link.text }}</a>
              </div>
            </div>

            <!-- Related Services -->
            <div v-if="showRelated" class="mt-8">
              <h3 class="font-bold text-sm tracking-wide text-gray-600 uppercase">Related Services</h3>
              <div class="mt-5 grid grid-cols-1 gap-3 text-gray-600 font-medium">
                <a v-for="service in frontmatter.related" :key="service.subService" @click.prevent="launchProduct(service)" class="text-sm hover:text-gray-900 transition ease-in-out duration-150 cursor-pointer">
                  Launch {{ service.label }}</a>
              </div>
            </div>

            <!-- Downloads -->
            <div v-if="frontmatter.media" class="mt-8">
              <h3 class="font-bold text-sm tracking-wide text-gray-600 uppercase">Downloads</h3>
              <div class="mt-5 grid grid-cols-1 gap-3 text-gray-600 font-medium">
                <div v-for="link in frontmatter.media" :key="link.href" class="flex items-center hover:text-gray-900">
                  <Icon name="download" outline class="w-4 h-4 mr-2" />
                  <a :href="require(`@/${link.href}`).default" target="_blank" class="text-sm transition ease-in-out duration-150">{{ link.label }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="relative h-screen bg-gray-900 flex items-center justify-center">
      <div class="absolute inset-0">
        <ParticleField />
      </div>
      <div class="relative text-white text-6xl font-bold flex items-center">
        <div>
        404 <div class="leading-none text-2xl ml-1">Not Found</div>
        </div>
        <div class="ml-10 -mt-3">
          <Button color="blue" size="xl" @click="$router.back()">
            <Icon name="arrow-left" outline class="w-4 h-4 -ml-1 mr-2" />
            Back
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as components from '@/components'
const { Badge, Button, Header, ParticleField } = components
import Image from '@/components/Image'
import Icon from 'vue-heroicon-next'
import { launch } from '@/behaviors/launcher'
import snackbar from '@/behaviors/snackbar'
import { slugify } from '@/behaviors/utils'
import { shallowRef } from 'vue'
import { mapState } from 'vuex'

export default {
  name: 'Content',

  components: { Badge, Button, Header, Icon, ParticleField },

  props: {
    path: {
      type: String
    }
  },

  data() {
    return {
      content: null,
      frontmatter: null,
      notFound: false,
      navigation: [],
      loading: false,
    }
  },

  created() {
    try {
      const markdown = require(`@/content${this.path}.md`)

      // Grab frontmatter data off the Markdown file.
      this.frontmatter = markdown.attributes

      /**
       * This ends up being a Vue component instance that is wrapping
       * the component instance / render function that frontmatter-markdown-loader
       * creates for us when we require a `*.md` file.
       *
       * Further, we register *all* of our visual components so that means
       * they are actually usable inside the Markdown contents!
       */
      this.content = shallowRef({
        name: 'ProductPage',
        template: markdown.html,
        components: {
          ...components,
          Image
        }
      })
    } catch (e) {
      this.content = null
      this.notFound = true
    }
  },

  computed: {
    ...mapState(['user', 'noClusterRequired']),

    showTopics() {
      return this.navigation.length > 0
    },

    showRelated() {
      return this.frontmatter && this.frontmatter.related
    },

    launchEnabled() {
      return (this.user.hasCluster || this.noClusterRequired.includes(this.frontmatter.launcher.id))
    }
  },

  mounted() {
    if (this.content) {
      const headings = Array.from(this.$refs.markdown.querySelectorAll('h2'))
      this.navigation = headings.reduce((links, heading) => {
        links.push({
          text: heading.innerText,
          href: `#${slugify(heading.querySelector('.header-anchor').hash)}`
        })

        return links
      }, [])
    }
  },

  methods: {
    launchProduct(product) {
      this.loading = true,

      // Clean up cookies from read only services
      document.cookie = "pcReadOnly=false"
      document.cookie = "vCenterReadOnly=false"

      launch(product).then(() => {
        //
      }).catch(error => {
        snackbar.show({
          message: `We were unable to launch this product. Please report this in our issue tracker.`,
          tone: 'critical'
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

