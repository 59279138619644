<template>
  <div>
    <Modal v-model="modal.visible">
      <div class="max-w-sm px-5">
        <div class="mx-auto flex items-center justify-center">
          <Icon name="clock" outline class="w-10 h-10 text-gray-700" />
        </div>

        <div class="mt-4 text-center">
          <h3 class="text-2xl leading-6 font-medium text-gray-900">
            Are you still there?
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-600">
              You haven't been active for quite some time.
            </p>
          </div>
        </div>
      </div>

      <div class="mt-6 flex items-center justify-center">
        <Button color="blue" @click="modal.visible = false">Keep Going</Button>
        <Button class="ml-2" @click="markInactive">
          <Timer expires="60s" resolution="seconds" @expire="markInactive">
            <template v-slot="{ remaining }">
              Sign Out ({{ remaining }})
            </template>
          </Timer>
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import ms from 'ms'
import { Idle } from 'idlejs/dist'
import Icon from 'vue-heroicon-next'

import { Button, Modal, Timer } from '@/components'

export default {
  name: 'ActivityMonitor',

  components: { Button, Icon, Modal, Timer },

  props: {
    maxIdle: {
      validator: (value) => {
        try {
          ms(value)

          return true
        } catch {
          return false
        }
      },
      default: '1m'
    },

    enabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      modal: {
        visible: false,
      }
    }
  },

  watch: {
    enabled(value) {
      if (value) {
        this.activate()
      } else {
        this.deactivate()
      }
    }
  },

  methods: {
    promptUserForActivity() {
      if (this.modal.visible) {
        return
      }

      this.modal.visible = true
    },

    markInactive() {
      this.modal.visible = false
      this.$emit('inactive')
    },

    activate() {
      this.idle = new Idle()
        .whenNotInteractive()
        .within(ms(this.maxIdle), 1)
        .do(this.promptUserForActivity)
        .start()
    },

    deactivate() {
      if (this.idle instanceof Idle) {
        this.idle.stop()
      }
    }
  },

  mounted() {
    if (this.enabled) {
      this.activate()
    }
  },

  beforeDestroyed() {
    this.idle.stop()
    delete this.idle
  }
}
</script>
