<template>
  <div class="flex items-center" :class="classes[tone]">
    <div class="w-6 h-6">
      <Icon v-if="tone === 'info'" name="information-circle" outline />
      <Icon v-if="tone === 'critical'" name="x-circle" outline />
      <Icon v-if="tone === 'positive'" name="check-circle" outline />
      <Icon v-if="tone === 'promote'" name="sparkles" />
    </div>
    <p class="ml-1 text-sm font-semibold leading-5">
      <slot></slot>
    </p>
  </div>
</template>

<script>
import Icon from 'vue-heroicon-next'

export default {
  name: 'Notice',

  components: { Icon },

  props: {
    tone: {
      type: String,
      validator: (val) => ['info', 'promote', 'critical', 'positive'].includes(val),
      default: 'info'
    }
  },

  data() {
    return {
      classes: {
        info: 'text-blue-800',
        promote: 'text-green-800',
        critical: 'text-red-800',
        positive: 'text-green-800'
      }
    }
  }
}
</script>
