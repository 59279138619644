<template>
  <div class="h-screen">
    <div v-show="loading" class="absolute inset-0 z-0">
      <div class="h-screen flex items-center justify-center bg-gray-100">
        <Loader color="#888" :size="200" />
      </div>
    </div>

    <div class="absolute inset-0 z-10">
      <iframe ref="iframe" id="demoFrameBody" name="demoframebody" :src="iframeSource" scrolling="yes" :key="selectCounter" class="w-full h-screen"></iframe>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex'
import Loader from '@/components/core/Loader'
import { performRewrite } from '@/behaviors/rewrites'

export default {
  name: 'DemoFrame',

  components: { Loader },

  data() {
      return {
        loading: true
      }
  },

  computed: {
      ...mapState(['iframeSource', 'selectCounter'])
  },

  mounted() {
    this.registerEvents()
    global.counter = 0
  },

  beforeUnmount() {
    this.$refs.iframe.removeEventListener('load', this.handleFrameLoad)
  },

  updated() {
    this.registerEvents()
  },

  methods: {
    registerEvents() {
      const iframe = this.$refs.iframe
      this.handleFrameLoad = (e) => {
        const iframeDocument = this.$refs.iframe.contentWindow
        iframeDocument.onmousemove = (e) => {
          // Bubble mouse movement to document so it is picked up
          // by ActivityMonitor - This is how we detrmine when to
          // pop the are you still here message
          document.dispatchEvent(new MouseEvent("mousemove", {
            bubbles: true,
            cancelable: true,
            clientX: e.clientX,
            clientY: e.clientY
          }))
          performRewrite(e)
        }
        iframeDocument.onmouseover = (e) => performRewrite(e)
        iframeDocument.onmousedown = (e) => performRewrite(e)
        this.loading = false
      }
      iframe.addEventListener('load', this.handleFrameLoad)
    }
  }
}
</script>
