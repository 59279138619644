<template>
  <div>
    <div v-show="false" class="bg-blue-700">
      <div class="py-3 px-5">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-blue-800">
              <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"/>
              </svg>
            </span>
            <p class="ml-3 font-medium text-white text-sm lg:text-base pr-4">
              <!-- Banner text would go here -->
            </p>
          </div>
          <div class="order-3 mt-4 sm:mt-0 flex-shrink-0 w-full sm:order-2 sm:w-auto">
            <div class="rounded-md shadow-sm">
              <router-link :to="{ name: 'faq' }" class="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-700 bg-white hover:text-blue-600 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                Check out the FAQ
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Alert tone="caution" v-show="resourcesUnavailable">There are currently no demo clusters available. Please try again later.</Alert>
    <!-- On-Demand Product Tiles -->
    <nav class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 gap-8 p-8">
      <div v-for="(tile, index) in noClusterRequredTiles" :key="index">
        <Card>
          <template #header>
            <div class="flex items-center justify-between">
              <h2 class="uppercase tracking-wider">{{ tile.title }}</h2>
              <div v-if="configModeActive" class="w-6 h-6 text-gray-600">
                <div v-if="isHidden(tile.id)" @click.prevent.stop="toggleTileHidden(tile.id)">
                  <Icon name="eye-off" outline />
                </div>
                <div v-else @click.prevent.stop="toggleTileHidden(tile.id)">
                  <Icon name="eye" outline />
                </div>
              </div>
            </div>
          </template>

          <!-- Content -->
          <router-link :to="`/products/${slugify(tile.title)}`">
            <div class="flex items-start h-20">
              <img class="w-10 h-10 mr-6" :src="tile.img" />

              <div class="flex-1">
                <p class="text-sm text-gray-700 leading-6 -mt-1">{{ tile.description }}</p>
              </div>
            </div>

            <div class="flex items-center justify-end mt-4">
                <span class="text-sm text-gray-600 mr-2">Learn More</span><Icon class="w-4 h-4 text-gray-700" name="arrow-circle-right" outline />
            </div>
          </router-link>

          <template #footer>
            <div class="flex items-center justify-between">
              <ComboButton v-if="readOnly.includes(tile.id)" :buttonValues="readOnlyComboButton" @launch="launchProduct" :loading="launching" :disabled="!isLaunchable(tile)"/>

              <Button v-else color="blue" class="mr-3" @click.prevent="launchProduct(tile)" :loading="isLaunching(tile)" :disabled="!isLaunchable(tile)">
                Launch
              </Button>

              <div v-if="hasSlackSupport(tile)" class="flex items-center justify-end">
                <a :href="`https://slack.com/app_redirect?channel=${tile.slackLink}`" @click.stop target="_blank">
                  <img class="relative h-6 w-6 z-0" :src="require('../assets/logos/Slack_Mark.svg')" />
                </a>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </nav>

    <!-- On-Prem Product Tiles -->
    <div class="mx-4  mt-12">
      <div class="flex items-center mb-4 space-x-8 ml-2">
        <h3 class="text-lg text-gray-700 font-semibold">Nested AHV Services</h3>
        <Button v-show="user.hasCluster == false" color="blue" @click.prevent="sendClusterRequest" :loading="requestingCluster" v-tippy content="Request a nested AHV cluster to access the below services">Request Cluster</Button>
        <Button v-show="user.hasCluster == true" color="blue" @click.prevent="launchProduct(prismElementTile)" :loading="isLaunching(prismElementTile)">Prism Element</Button>
        <Button v-show="user.hasCluster == true" color="blue" @click.prevent="launchProduct(prismCentralTile)" :loading="isLaunching(prismCentralTile)">Prism Central</Button>
      </div>
      <hr>
    </div>
    <nav class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 gap-8 p-8">
      <router-link :to="`/products/${slugify(tile.title)}`" v-for="(tile, index) in clusterRequiredTiles" :key="index">
        <Card>
          <template #header>
            <div class="flex items-center justify-between">
              <h2 class="uppercase tracking-wider">{{ tile.title }}</h2>
              <div v-if="configModeActive" class="w-6 h-6 text-gray-600">
                <div v-if="isHidden(tile.id)" @click.prevent.stop="toggleTileHidden(tile.id)">
                  <Icon name="eye-off" outline />
                </div>
                <div v-else @click.prevent.stop="toggleTileHidden(tile.id)">
                  <Icon name="eye" outline />
                </div>
              </div>
            </div>
          </template>

          <!-- Content -->
          <div class="flex items-start h-20">
            <img class="w-10 h-10 mr-6" :src="tile.img" />

            <div class="flex-1">
              <p class="text-sm text-gray-700 leading-6 -mt-1">{{ tile.description }}</p>
            </div>
          </div>

          <div class="flex items-center justify-end mt-4">
              <span class="text-sm text-gray-600 mr-2">Learn More</span><Icon class="w-4 h-4 text-gray-700" name="arrow-circle-right" outline />
          </div>

          <template #footer>
            <div class="flex items-center justify-between">
              <Button v-show="user.hasCluster" color="blue" class="mr-3" @click.prevent="launchProduct(tile)" :loading="isLaunching(tile)" :disabled="!isLaunchable(tile)">
                Launch
              </Button>
              <Button v-show="user.hasCluster == false" color="blue" @click.prevent="sendClusterRequest" :loading="requestingCluster" v-tippy content="This service requires you to request a nested cluster before launching.">
                Request Cluster
              </Button>

              <div v-if="hasSlackSupport(tile)" class="flex items-center justify-end">
                <a :href="`https://slack.com/app_redirect?channel=${slackLink}`" @click.stop target="_blank">
                  <img class="relative h-6 w-6 z-0" :src="require('../assets/logos/Slack_Mark.svg')" />
                </a>
              </div>
            </div>
          </template>
        </Card>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Icon from 'vue-heroicon-next'
import { Alert, Button, Card } from '@/components'
import { slugify } from '@/behaviors/utils'
import { launch } from '@/behaviors/launcher'
import ComboButton from '@/components/core/ComboButton.vue'

export default {
  name: "LandingCards",

  components: { Alert, Icon, Button, Card, ComboButton },

  data() {
    return {
      launching: null,
      requestingCluster: false,
      resourcesUnavailable: false,
      readOnly: [ 14, 15 ]
    }
  },

  computed: {
    ...mapState(['demoTiles', 'user', 'noClusterRequired', 'prismTiles']),

    noClusterRequredTiles() {
      let notHidden = this.demoTiles.filter(t => !this.isHidden(t.id))
      return notHidden.filter(t => this.noClusterRequired.includes(t.id) && !t.hideByDefault)
    },

    clusterRequiredTiles() {
      let notHidden = this.demoTiles.filter(t => !this.isHidden(t.id))
      return notHidden.filter(t => !this.noClusterRequired.includes(t.id) && !this.prismTiles.includes(t.id) && !t.hideByDefault)
    },

    prismElementTile() {
      let [peTile] = this.demoTiles.filter(t => t.id == 0)
      return peTile
    },

    prismCentralTile() {
      let [pcTile] = this.demoTiles.filter(t => t.id == 1)
      return pcTile
    },

    hiddenTilesArray() {
      return this.user.settings.hiddenTiles ? this.user.settings.hiddenTiles : []
    },

    configModeActive() {
      return this.$store.state.configMode;
    },

    readOnlyComboButton() {
      return this.demoTiles.filter(t => this.readOnly.includes(t.id))
    }
  },

  methods: {
    ...mapActions(['fetchCurrentUser', 'requestCluster', 'sendUserSettingsUpdate']),

    slugify,

    async sendClusterRequest() {
      this.requestingCluster = true
      try {
        await this.requestCluster()
      }
      catch(err) {
        console.error(err)
      }
      finally {
        this.requestingCluster = false
      }
    },

    launchProduct(product) {
      this.launching = product

      // Clean up cookies from read only services
      document.cookie = "pcReadOnly=false"
      document.cookie = "vCenterReadOnly=false"


      launch(product).then(() => {
        // Report product usage to Google Analytics
        this.$gtag.event(`${product.serviceName}_${product.subService}`)
      }).catch(error => {
        if (error.response.status == 401) {
          this.$emit("sessionTimeout");
        } else {
          this.$emit("launchError");
        }
      }).finally(() => {
        this.launching = null
      })
    },

    isLaunching(product) {
      if (this.launching === null) {
        return false
      }

      return this.launching == product
    },

    isLaunchable(tile) {
      return (this.noClusterRequired.includes(tile.id) || this.user.hasCluster)
    },

    isHidden(id) {
      return this.hiddenTilesArray.includes(id);
    },

    toggleTileHidden(id) {
      this.$store.commit('TOGGLEHIDDENTILE', id)
    },

    unhideAllTiles() {
      this.$store.commit('RESETHIDDENTILES')
    },

    saveHiddenCards() {
      this.sendUserSettingsUpdate()
    },

    hasSlackSupport(tile) {
      return this.user.partner === false && tile.slackLink
    }
  },

  async created() {
    await this.fetchCurrentUser()
    this.$gtag.set("user_properties", {
      user_type: this.user.partner ? 'partner' : 'employee'
    })
  }
}
</script>
