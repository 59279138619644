<template>
  <div>
    <slot 
      :remaining="formattedRemaining" 
      :status="status"
    ></slot>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import ms from 'ms'

momentDurationFormatSetup(moment)

export default {
  name: 'Timer',

  props: {
    expires: {
      type: String,
      required: true
    },

    resolution: {
      type: String,
      validator: (val) => ['seconds', 'minutes'].includes(val),
      default: 'minutes'
    },

    warn: {
      type: Number,
      default: 0
    },

    crit: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      now: moment(),
      expiresAt: null,
      notificationsSent: {
        warn: false,
        crit: false
      }
    }
  },

  computed: {
    tickRate() {
      if (this.resolution === 'minutes') {
        return 60000
      } else {
        return 1000
      }
    },

    remaining() {
      return moment.duration(moment(this.expiresAt).diff(this.now))
    },

    formattedRemaining() {
      if (this.status === 'expired') {
        return 0
      }

      return Math.round(this.remaining.as(this.resolution))
    },

    status() {
      if (this.remaining.as(this.resolution) <= this.warn && this.remaining.as(this.resolution) > this.crit) {
        return 'warn'
      } else if (this.remaining.as(this.resolution) <= this.crit && this.remaining.as(this.resolution) > 0) {
        return 'crit'
      } else if (this.remaining.as(this.resolution) <= 0) {
        return 'expired'
      } else {
        return 'ok'
      }
    }
  },

  methods: {
    tick() {
      this.now = moment()

      if (this.status === 'warn' && !this.notificationsSent.warn) {
        this.$emit('warn')
        this.notificationsSent.warn = true
      }

      if (this.status === 'crit' && !this.notificationsSent.crit) {
        this.$emit('crit')
        this.notificationsSent.crit = true
      }

      if (this.status === 'expired') {
        this.$emit('expire')
        clearInterval(this.ticker)
      }
    }
  },

  created() {
    try {
      // Try processing expiry as a human-readable duration "from now"
      // If this fails, we treat it as a date and send to moment.
      this.expiresAt = moment(this.now)
                        .add(ms(this.expires), 'ms')
                        .startOf('second')
    } catch {
      this.expiresAt = moment(this.expires)
    }

    this.ticker = setInterval(this.tick, this.tickRate)
  },

  destroyed() {
    clearInterval(this.ticker)
  }
}
</script>