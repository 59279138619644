<template>
  <div>
    <Header />

    <div class="max-w-5xl mx-auto py-10 px-8">
      <div class="flex items-center justify-between">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">Issue Tracker</h2>
        <Modal @dismiss="clearFormData()" @close="clearFormData()" v-model="modal.visible">
          <template #trigger="{ actions }">
            <Button color="blue" @click="actions.show">
              <div class="flex items-center">
                <Icon name="annotation" outline class="w-5 h-5 -ml-1 mr-2" />
                Report an Issue
              </div>
            </Button>
          </template>

          <template #default="{ actions }">
            <div class="p-2 w-screen max-w-xl">
              <form @submit.prevent="handleCreateIssue(actions)">
                <div>
                  <h3 class="block text-2xl font-medium text-gray-800">Report an Issue</h3>
                  <p class="mt-2 text-gray-800 leading-5 text-sm">You can help improve Demo by giving us feedback about any problems you're having. If reporting a bug, be sure to include as much detail as possible to reproduce.</p>
                </div>
                <div class="flex mt-5">
                  <!-- Input Fields -->
                  <div class="flex-grow">
                    <div>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <label for="issue-title" class="block font-medium leading-5 text-gray-800 mb-2">Title</label>
                        <input v-model="modal.form.title" id="issue-title" class="block w-full p-2 border border-gray-400 rounded focus:shadow-outline focus:outline-none leading-tight" />
                      </div>
                    </div>
                    <div class="mt-8">
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <label for="issue-title" class="block font-medium leading-5 text-gray-800 mb-2">Description</label>
                        <textarea v-model="modal.form.description" id="issue-description" rows="12" class="block text-sm text-gray-800 w-full py-3 px-3 border border-gray-400 rounded focus:shadow-outline focus:outline-none leading-tight" style="resize:none;" placeholder="Please provide some insight on the issue" />
                      </div>
                    </div>
                    <div class="mt-8">
                      <label for="issue-rating" class="block font-medium text-gray-800">Impact</label>
                      <p class="text-gray-800 text-sm">How impactful do you feel this is?</p>
                      <div class="mt-2 flex justify-between py-3 px-2">
                        <input v-model="modal.form.impact" value="P4 - Trivial" type="radio" id="impact1" name="impact1" class="h-6 w-4" />
                        <input v-model="modal.form.impact" value="P3 - Minor" type="radio" id="impact2" name="impact2" class="h-6 w-4" />
                        <input v-model="modal.form.impact" value="P2 - Major" type="radio" id="impact3" name="impact3" class="h-6 w-4" />
                        <input v-model="modal.form.impact" value="P1 - Critical" type="radio" id="impact4" name="impact4" class="h-6 w-4" />
                        <input v-model="modal.form.impact" value="P0 - Blocker" type="radio" id="impact5" name="impact5" class="h-6 w-4" />
                      </div>
                      <div class="flex justify-between mx-1">
                        <p class="text-gray-800 text-xs">Low</p>
                        <p class="text-gray-800 text-xs">Medium</p>
                        <p class="text-gray-800 text-xs">High</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Form Controls -->
                <div class="mt-8 flex justify-end">
                  <Button @click="actions.close">Cancel</Button>
                  <Button :loading="modal.form.loading" color="blue" type="submit" class="ml-3">Submit</Button>
                </div>
              </form>
            </div>
          </template>
        </Modal>

      </div>
      <div class="mt-8 border-t border-gray-200 pt-8">
        <div class="md:flex items-center justify-between">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">We're actively building the Next Gen Demo.</h3>
            <p class="mt-1 text-sm leading-5 text-gray-700 max-w-md" >
              Below are issues that have been reported by the community, discovered by the development team or being actively worked on.
            </p>
          </div>

          <input v-model="form.filter" class="w-full block mt-4 md:mt-0 md:flex-1 md:max-w-sm text-sm bg-gray-100 text-gray-800 placeholder-gray-600 py-3 px-4 rounded-lg" type="text" placeholder="Filter..." />
        </div>

        <div class="mt-10">
          <Masonry :bricks="results">
            <template v-slot="{ brick: issue }">
              <Card>
                <h4>{{ issue.title }}</h4>

                <div class="-ml-1 mt-2">
                  <Badge :tone="issue.status === 'Done' ? 'positive' : 'neutral'">
                    <Icon v-if="issue.status === 'Done'" name="check-circle" outline class="w-4 h-4 -ml-1 mr-1 text-green-700" />
                    {{ issue.status }}
                  </Badge>
                </div>
                <p v-if="issue.description" class="text-sm text-gray-600 mt-3" v-html="issue.description" />

                <div class="mt-6 flex flex-wrap">
                  <div class="mr-2 mb-2" v-for="(badge, i) in issue.badges" :key="i">
                  <Badge :tone="badge.tone"
                    >{{ badge.text }}</Badge>
                  </div>
                </div>
              </Card>
            </template>
          </Masonry>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex'
import Fuse from 'fuse.js'
import { Badge, Button, Card, Header, Modal, Masonry } from '@/components'
import Icon from 'vue-heroicon-next'
import issues from '@/services/issues'
import issueTemplate from '@/assets/misc/issue-template.txt'
import snackbar from '@/behaviors/snackbar'

export default {
  name: 'IssueTracker',

  components: { Badge, Button, Card, Header, Icon, Modal, Masonry },

  data() {
    return {
      form: {
        filter: ''
      },
      modal: {
        visible: false,
        form: {
          title: '',
          description: issueTemplate,
          impact: '',
          loading: false,
        }
      }
    }
  },

  computed: {
    ...mapState('rest', ['issues']),

    results() {
      let results = []

      if (this.form.filter.length === 0) {
        results = this.issues
      } else {
        results = this.search(this.form.filter, this.issues, ['title', 'type', 'badges.text'])
      }

      // Pin Known Issues to top of results
      // TODO: It's probably worth putting these in a _dedicated_ space on the issue
      //       tracker similar to how GitHub pins issues / repositories.
      results.sort((a, b) => {
        const knownIssueA = a.badges.filter(b => b.text === 'Known Issue').length
        const knownIssueB = b.badges.filter(b => b.text === 'Known Issue').length

        return knownIssueB - knownIssueA
      })

      return results
    }
  },

  methods: {
    ...mapActions('rest', ['fetch']),

    search(needle, haystack, keys = [], results = 3) {
      const fuse = new Fuse(haystack, { keys })

      return fuse.search(needle)
        .map(hit => hit.item)
        .slice(0, results)
    },

    handleCreateIssue(action) {
      this.modal.form.loading = true

      issues.create(this.modal.form)
        .then(() => {
          // Close the modal.
          action.close()

          // Fetch issues from Vuex.
          this.fetch()

          snackbar.show({
            message: 'Thank you for your feedback! Once we take a look, it will show up on the issue tracker',
            position: 'top',
            tone: 'positive',
            timeout: 10000
          })
        })
    },

    clearFormData(){
      this.modal.form = {
        title: '',
        description: issueTemplate,
        impact: '',
        loading: false,
      }
    }
  }
};
</script>


