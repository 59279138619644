import api from './api'

export async function updateUserSettings(payload) {
  const response = await api.put(`/api/v1/settings`, payload)
  return {
    settings: response.data.settings
  }
}

export default {
  updateUserSettings
}