import issues from '@/services/issues'
import snackbar from '@/behaviors/snackbar'

export default {
  namespaced: true,

  state: {
    issues: []
  },

  mutations: {  
    replace(state, { type, items }) {
      state[type] = items
    }
  },

  actions: {
    fetch(context) {
      issues.index().then(issues => {
        context.commit('replace', {
          type: 'issues', 
          items: issues
        })
      }).catch(e => {
        if (e.message !== 'Request aborted') {
          snackbar.show({
            tone: 'critical',
            timeout: 3000,
            message: 'There was an error loading issues from Asana',
            position: 'bottom'
          })
        } 
      })
    }
  }
}