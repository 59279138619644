<template>
  <div :class="[classes.all, shadow === true ? classes['shadow'] : '', classes[tone]]" role="alert">
    <div class="flex items-center">
      <div class="w-6 h-6 mr-3">
        <Icon v-if="tone === 'info'" name="information-circle" outline />
        <Icon v-if="tone === 'caution'" name="exclamation" outline />
        <Icon v-if="tone === 'critical'" name="x-circle" outline />
        <Icon v-if="tone === 'positive'" name="check-circle" outline />
      </div>
      <slot></slot>
    </div>
    <Icon v-if="dismissable" @click.native="$emit('dismiss')" name="x" outline class="w-6 h-6 cursor-pointer" />
  </div>
</template>

<script>
import Icon from 'vue-heroicon-next'

export default {
  name: 'Alert',

  components: { Icon },

  props: {
    tone: {
      type: String,
      validator: (val) => ['info', 'caution', 'critical', 'positive'].includes(val),
      default: 'info'
    },

    dismissable: {
      type: Boolean,
      default: false
    },

    shadow: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      classes: {
        all: 'border-t-4 px-5 py-4 flex items-center justify-between',
        shadow: 'shadow-md',
        info: 'bg-blue-100 border-blue-500 text-blue-900',
        caution: 'bg-yellow-100 border-yellow-500 text-yellow-900',
        critical: 'bg-red-100 border-red-500 text-red-900',
        positive: 'bg-green-100 border-green-500 text-green-900'
      }
    }
  }
}
</script>
